import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import IconButton from '../components/IconButton';
import ItemStatRow from '../components/ItemStatRow';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class PurchasePopup extends Phaser.Scene {
	constructor() {
		super('PurchasePopup');
	}
	static soundOn = true;

	initialize() {
		Phaser.Scene.call(this, { key: 'PurchasePopup' });
	}

	async init(data) {
		console.log(data);

		this.itemData = data;
	}

	preload() {}

	create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 550, 650, 40, 40, 40, 50);
		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Purchase', AppController.getText(40)).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('PurchasePopup');
		});
		var icon = this.add.image(popupBG.x, titleBg.y + 150, 'main2', this.itemData.thumb);
		var name = this.add.text(popupBG.x, icon.y + icon.height, this.itemData.name, { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: '#fff200', align: 'left' }).setOrigin(0.5, 0.5);
		var label = this.add.text(popupBG.x, name.y + name.height, this.itemData.label, AppController.getText(40)).setOrigin(0.5, 0.5);

		this.childPanel = this.rexUI.add.sizer({
			x: popupBG.x,
			y: name.y + 200,
			orientation: 'y',
			space: { item: 20, top: 10, bottom: 10 },
		});

		var tonPurchase = new PopupButton(this, 0, 0, 'button_green', this.itemData.ton_price + ' TON', 250);
		tonPurchase.on('pointerup', async function() {
			this.scene.scene.launch('LockScreen');
			const response = await AppController.createTempPawTransaction(this.scene.itemData.id);

			console.log(response.secret);

			var description = AppController.getUserId() + '_' + response.secret;

			const returnBoc = await AppController.sendMoneyTon(this.scene.itemData.ton_price, description);
			console.log('returnBoc', returnBoc);

			//const updateResponse = await updateBocTransaction(response.secret, returnBoc.boc);

			//console.log('updateResponse', updateResponse);

			this.scene.scene.stop('LockScreen');
		});

		var starPurchase = new PopupButton(this, 0, 0, 'button_green', this.itemData.price + ' Stars', 250);
		starPurchase.on('pointerup', async function() {
			this.scene.scene.launch('LockScreen');

			const response = await AppController.createTempPawTransaction(this.scene.itemData.id);

			var invoiceLink = await AppController.buyInappProduct(this.scene.itemData.id, response.secret);

			console.log('invoiceLink', invoiceLink);

			var starResponse = await AppController.openInvoice(invoiceLink.data);
			console.log(starResponse);

			this.scene.scene.stop('LockScreen');
		});

		this.childPanel.add(tonPurchase);
		this.childPanel.add(starPurchase);
		this.childPanel.layout();
	}
}
