import { Scene } from 'phaser';
import AppController from '../controller/AppController';

export default class Preloader extends Scene {
	constructor() {
		super('Preloader');
	}

	async init() {
		const userID = AppController.getUserId();
		//  We loaded this image in our Boot Scene, so we can display it here
		this.add.image(360, 650, 'loading-bg');
		this.add.image(360, 150, 'game-logo-512').setScale(0.6, 0.6);
		const loadingText = this.add.text(360, 1150, '', { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);
		const progressBarY = 1100;

		//  A simple progress bar. This is the outline of the bar.
		this.add.rectangle(360, progressBarY, 468, 14).setStrokeStyle(1, 0x5bbe39);

		//  This is the progress bar itself. It will increase in size from the left based on the % of progress.
		const bar = this.add.rectangle(360 - 230, progressBarY, 4, 10, 0x5bbe39);

		//  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
		this.load.on('progress', progress => {
			//  Update the progress bar (our bar is 464px wide, so 100% = 464px)
			bar.width = 4 + 460 * progress;
		});
		this.connected = false;
		loadingText.text = userID + ' connecting to server...';
		//const user = await getData();
		const user = await AppController.getData();
		loadingText.text = user.uid + ' connected.';
		console.log('Connected.');
		this.connected = true;
	}

	preload() {
		this.load.image('maintain', './maintain_bg.jpg');
		this.load.image('maintain-fx', './loading_fx.png');

		//this.load.atlas('main', './dogmutant.png', './dogmutant.json');

		this.load.atlas('main2', './dogmutant_main.png', './dogmutant_main.json');

		this.load.spineJson('shimo-data', './dogmutant_spine/ShimoBoss.json');
		this.load.spineAtlas('shimo-atlas', './dogmutant_spine/ShimoBoss.atlas.txt');

		this.load.spineJson('dogzilla-data', './dogmutant_spine/dogzilla.json');
		this.load.spineAtlas('dogzilla-atlas', './dogmutant_spine/dogzilla.atlas.txt');

		this.load.spineJson('dog_kong-data', './dogmutant_spine/dog_kong.json');
		this.load.spineAtlas('dog_kong-atlas', './dogmutant_spine/dog_kong.atlas.txt');

		this.load.spineJson('dognom-data', './dogmutant_spine/dognom.json');
		this.load.spineAtlas('dognom-atlas', './dogmutant_spine/dognom.atlas.txt');

		this.load.spineJson('dog_shiba-data', './dogmutant_spine/dog_shiba.json');
		this.load.spineAtlas('dog_shiba-atlas', './dogmutant_spine/dog_shiba.atlas.txt');

		this.load.spineJson('skar_king-data', './dogmutant_spine/skar_king.json');
		this.load.spineAtlas('skar_king-atlas', './dogmutant_spine/skar_king.atlas.txt');

		this.load.spineJson('dogkong-impact-data', './dogmutant_spine/impact_skill.json');
		this.load.spineAtlas('dogkong-impact-atlas', './dogmutant_spine/impact_skill.atlas.txt');

		this.load.spineJson('irondog-data', './dogmutant_spine/irondog.json');
		this.load.spineAtlas('irondog-atlas', './dogmutant_spine/irondog.atlas.txt');

		this.load.spineJson('dogfly-data', './dogmutant_spine/DogFly.json');
		this.load.spineAtlas('dogfly-atlas', './dogmutant_spine/DogFly.atlas.txt');

		this.load.spineJson('punchingbag-data', './dogmutant_spine/punching_bag.json');
		this.load.spineAtlas('punchingbag-atlas', './dogmutant_spine/punching_bag.atlas.txt');

		this.load.spineJson('mechzilla-data', './dogmutant_spine/mechzilla.json');
		this.load.spineAtlas('mechzilla-atlas', './dogmutant_spine/mechzilla.atlas.txt');

		this.load.spineJson('rocket-data', './dogmutant_spine/Rocket.json');
		this.load.spineAtlas('rocket-atlas', './dogmutant_spine/Rocket.atlas.txt');

		this.load.spineJson('rocket-impact-data', './dogmutant_spine/FxImpact.json');
		this.load.spineAtlas('rocket-impact-atlas', './dogmutant_spine/FxImpact.atlas.txt');

		this.load.spineJson('mutantdog-data', './Mutant.json');
		this.load.spineAtlas('mutantdog-atlas', './Mutant.atlas.txt');

		this.load.spineJson('button-data', './effect_spine/Button.json');
		this.load.spineAtlas('button-atlas', './effect_spine/Button.atlas.txt');

		this.load.spineJson('aura-data', './effect_spine/Aura.json');
		this.load.spineAtlas('aura-atlas', './effect_spine/Aura.atlas.txt');

		this.load.spineJson('lightning-data', './effect_spine/Lightning.json');
		this.load.spineAtlas('lightning-atlas', './effect_spine/Lightning.atlas.txt');

		this.load.image('bg-1', './bg-1.jpg');
		this.load.image('bg-2', './bg-2.jpg');
		this.load.image('bg-3', './bg-3.jpg');
		this.load.image('bg-4', './bg-4.jpg');
		this.load.image('bg-5', './bg-5.jpg');

		this.load.image('shop-banner-1', './shop_offers_01_none.jpg');
		this.load.image('shop-banner-2', './shop_offers_02_none.jpg');

		this.load.image('choose_character_bg', './choose_character_bg.jpg');

		this.load.audio('main', './sound/main.mp3');
		this.load.audio('button', './sound/button.mp3');
		this.load.audio('coin_drop', './sound/coin_drop.mp3');
		this.load.audio('battle_01', './sound/battle_01.mp3');
		this.load.audio('battle_02', './sound/battle_02.mp3');
		this.load.audio('coin_drop', './sound/coin_drop.mp3');
		this.load.audio('hit_cast', './sound/hit_cast.mp3');
		this.load.audio('hit_rocket', './sound/hit_rocket.mp3');
		this.load.audio('hit_punch', './sound/hit_punch.mp3');
		this.load.audio('hit_shot', './sound/hit_shot.mp3');
		this.load.audio('hit_tail', './sound/hit_tail.mp3');
	}

	create() {
		//  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
		//  For example, you can define global animations here, so we can use them in other scenes.
		//  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
		//
		/*this.gamebg = this.add.image(0, 0, 'maintain');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;
		
		this.fx = this.add.image(this.gamebg.x, this.gamebg.y - 80, 'maintain-fx');

		// Set the date we're counting down to
		//var countDownDate = new Date('Jul 6, 2024 15:37:25').getTime();

		var countDownDate = Date.UTC(2024, 6, 6, 12, 0);

		// Update the count down every 1 second
		var x = setInterval(function() {
			// Get today's date and time
			var now = new Date().getTime();

			// Find the distance between now and the count down date
			var distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);

			// Display the result in the element with id="demo"
			var waitText = 'Please wait for: ' + days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
			countDownText.setText(['We are preparing to update something very cool!', waitText]);

			// If the count down is finished, write some text
			if (distance < 0) {
				clearInterval(x);
			}
		}, 1000);*/
	}

	update() {
		//this.fx.rotation += 0.02;
		if (this.connected) {
			this.scene.start('MainScene');
		}
	}
}
