import AppController from '../controller/AppController';
export default class ItemStatRow extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, icon, rarely, valueobj, updownArray, enhancedLevel = 0, isEnhancing = false) {
		console.log('updownarr' + updownArray);

		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_BIG_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '28px', fill: 'white', align: 'center' };

		super(scene, x, y);
		scene.add.existing(this);

		const color = { gray: '#ffffff', green: '#00ff00', blue: '#00a7fd', purple: '#fe0cfe', red: '#ff161f', yellow: '#fff200' };

		var row_bg = scene.add.nineslice(0, 0, 'main2', 'popup_row_bg', 500, 250 + (isEnhancing ? 100 : 0), 31, 5, 7, 32);
		var equipbg = scene.add.image(-190, -50 - (isEnhancing ? 50 : 0), 'main2', 'equip_rarity_' + rarely);
		var equipitem = scene.add.image(equipbg.x, equipbg.y, 'main2', icon);

		this.txt = scene.add.text(equipbg.x, equipbg.y, '', AppController.getText(24)).setOrigin(0.5, 0);
		this.txt.y += equipbg.height / 2 - 20;

		this.enhanceTxt = scene.add.text(equipbg.x + equipbg.width / 2 - 10, equipbg.y - equipbg.width / 2, '+' + enhancedLevel, AppController.getText(24)).setOrigin(0.5, 0);

		var label = 'Current';
		var colorLabel = 'blue';
		if (updownArray != null) {
			label = 'New';
			colorLabel = 'red';
		}

		var rowLabel = scene.add.image(row_bg.x + row_bg.width / 2 - 30, row_bg.y - row_bg.height / 2 + 5, 'main2', 'equip_label_' + colorLabel);

		var rowLabelText = scene.add.text(rowLabel.x, rowLabel.y, label, LABEL_BIG_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var itemname = scene.add.text(equipbg.x + equipbg.width, equipbg.y - equipbg.height / 2, name, AppController.getText(28, color[rarely]));

		this.setSize(row_bg.width, row_bg.height);
		this.setInteractive();

		this.add(row_bg);
		this.add(equipbg);
		this.add(equipitem);
		this.add(itemname);
		this.add(rowLabel);
		this.add(rowLabelText);
		this.add(this.txt);
		this.add(this.enhanceTxt);

		var startX = itemname.x + (isEnhancing ? -120 : 0);
		var startY = itemname.y + 50 + (isEnhancing ? 90 : 0);
		var lineGap = 0;
		var index = 0;
		//updownArray = [1, 1, 1];

		if (isEnhancing && enhancedLevel < 10) {
			var currentEnhancedTxt = scene.add.text(-80, -90, '+' + enhancedLevel, AppController.getText(50));
			this.add(currentEnhancedTxt);

			var currentArrow = scene.add.image(currentEnhancedTxt.x + 120, currentEnhancedTxt.y + 20, 'main2', 'enhance_arrow').setOrigin(0, 0);
			this.add(currentArrow);

			var nextEnhancedTxt = scene.add.text(130, -90, '+' + (enhancedLevel + 1), AppController.getText(50));
			this.add(nextEnhancedTxt);
		}

		Object.keys(valueobj).forEach(key => {
			console.log(`${key}: ${valueobj[key]}`);

			if (valueobj[key] == 0) {
				return;
			}

			if (key == 'lv') {
				this.txt.text = 'Lv.' + valueobj[key];
			}
			if (key == 'lv' && isEnhancing) {
				return;
			}

			var addedValue = 0;
			if (enhancedLevel > 0 && key != 'lv') {
				addedValue = Math.round((valueobj[key] * AppController.enhanceLevelList[enhancedLevel - 1].bonus_percent) / 100);
			}

			var labelName = scene.add.text(startX, startY + lineGap, key.toUpperCase(), LABEL_TEXT_CONFIG);
			var labelValue = scene.add.text(startX + 150 + (isEnhancing ? 0 : 90) + (enhancedLevel > 0 ? 0 : isEnhancing ? 0 : 40), startY + lineGap, valueobj[key] + addedValue, LABEL_TEXT_CONFIG);
			this.add(labelName);
			this.add(labelValue);
			var percentValue;
			if (key != 'lv' && enhancedLevel > 0) {
				//var addedPercentValue = Math.ceil(valueobj[key] * enhancedLevel * 0.02);
				var addedPercentValue = AppController.enhanceLevelList[enhancedLevel - 1].bonus_percent + '%)';
				percentValue = scene.add.text(labelValue.x + labelValue.width, labelValue.y, ' (+' + addedPercentValue, AppController.getText(24, !isEnhancing ? color.green : '#fff'));
				this.add(percentValue);
			}

			if (updownArray != null) {
				if (updownArray[index] == 1) {
					var updownIcon = scene.add.image(labelValue.x + labelValue.width + (percentValue != null ? percentValue.width : 0), startY + lineGap, 'main2', 'power_up').setOrigin(0, 0);
					this.add(updownIcon);
				}

				if (updownArray[index] == -1) {
					var updownIcon = scene.add.image(labelValue.x + labelValue.width, startY + lineGap, 'main2', 'power_down').setOrigin(0, 0);
					this.add(updownIcon);
				}
			}

			if (isEnhancing && enhancedLevel < 10) {
				var arrow = scene.add.image(labelValue.x + 120, labelValue.y, 'main2', 'enhance_arrow').setOrigin(0, 0);
				//var addedEnhancedPercentValue = Math.ceil(valueobj[key] * (enhancedLevel + 1) * 0.02);
				var addedEnhancedPercentValue = AppController.enhanceLevelList[enhancedLevel].bonus_percent + '%)';
				var enhanceLabelValue = scene.add.text(labelValue.x + 230, labelValue.y, valueobj[key] + Math.round((valueobj[key] * AppController.enhanceLevelList[enhancedLevel].bonus_percent) / 100), LABEL_TEXT_CONFIG);
				var percentEnhancedValue = scene.add.text(enhanceLabelValue.x + enhanceLabelValue.width, enhanceLabelValue.y, ' (+' + addedEnhancedPercentValue, AppController.getText(24, color.green));
				this.add(enhanceLabelValue);
				this.add(percentEnhancedValue);
				this.add(arrow);
			}

			lineGap += 40;
			index += 1;
		});
	}
}
