import { Scene } from 'phaser';
import AppController from '../controller/AppController';

export default class MaintainScene extends Scene {
	constructor() {
		super('MaintainScene');
	}

	async init() {}

	create() {
		this.gamebg = this.add.image(0, 0, 'maintain');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		this.fx = this.add.image(this.gamebg.x, this.gamebg.y - 80, 'maintain-fx');
		const countDownText = this.add.text(360, 1000, '', { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);

		// Set the date we're counting down to
		var countDownDate = AppController.getMaintain().maintainTime;

		//var countDownDate = Date.UTC(2024, 6, 6, 12, 0);

		// Update the count down every 1 second
		var x = setInterval(function() {
			// Get today's date and time
			var now = new Date().getTime();

			// Find the distance between now and the count down date
			var distance = countDownDate - now;

			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);

			// Display the result in the element with id="demo"
			var waitText = 'Please wait for: ' + days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
			countDownText.setText(['We are preparing to update something very cool!', waitText]);

			// If the count down is finished, write some text
			if (distance < 0) {
				clearInterval(x);
			}
		}, 1000);
	}

	update() {
		this.fx.rotation += 0.02;
	}
}
