import AppController from '../controller/AppController';
import NotificationDot from './NotificationDot';
export default class ImageButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, text, atlas = 'main2', normalState = '_none') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.atlas = atlas;
		this.txt = scene.add.text(0, 0, text, AppController.getText(24)).setOrigin(0.5, 0);
		this.bg = scene.add.image(0, 0, atlas, name + normalState);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);

		this.on('pointerover', function() {
			this.bg.setTexture(this.atlas, name + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture(this.atlas, name + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture(this.atlas, name + normalState);
		});

		this.on('pointerup', function() {
			this.bg.setTexture(this.atlas, name + '_active');
		});

		this.normalStateName = normalState;

		this.txt.y = this.height / 2 - 10;
	}

	showNotification() {
		this.notificationDot = new NotificationDot(this.scene, this.width / 2 - 10, -this.height / 2 + 10);
		this.add(this.notificationDot);
	}

	hideNotification() {
		if (this.notificationDot != null) {
			this.notificationDot.destroy();
		}
	}

	set text(value) {
		this.txt.text = value;
	}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			this.bg.setTexture(this.atlas, this.btnName + '_disable');
			//this.bg.setTexture(this.atlas, this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture(this.atlas, this.btnName + this.normalStateName);
		}
	}
}
