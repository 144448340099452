export default class CurrencySlot extends Phaser.GameObjects.Container {
	constructor(scene, x, y, icon, textWidth = 150, atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);

		this.icon = scene.add.image(0, 0, atlas, icon);
		this.icon.x -= this.icon.width + 30;
		this.txt = scene.add.text(0, 0, '0', { fontFamily: 'Barlow Condensed', fontSize: this.icon.height / 2 + 'px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
		//this.bg = scene.add.image(0, 0, atlas, name + '_none');
		this.bg = scene.add.nineslice(0, 0, atlas, 'currency_bg', textWidth, this.icon.height * 0.7, 5, 5, 5, 5);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.icon);
		this.add(this.txt);

		this.currentNumber = 0;
	}

	updateNumber(newNumber) {
		this.newNumber = newNumber;

		var lastText = '';
		if (this.maxNumber != null) {
			lastText =
				'/' +
				Intl.NumberFormat('en-US', {
					notation: 'standard',
					maximumFractionDigits: 1,
				}).format(this.maxNumber);
		}

		this.updateTween = this.scene.tweens.addCounter({
			from: this.currentNumber,
			to: newNumber,
			duration: 1000,
			ease: 'linear',
			onUpdate: tween => {
				const value = Math.round(tween.getValue());

				try {
					this.txt.setText(
						`${Intl.NumberFormat('en-US', {
							notation: 'standard',
							maximumFractionDigits: 1,
						}).format(value)}` + lastText
					);
				} catch (e) {}
			},
		});

		this.currentNumber = newNumber;

		/*

		if (this.updateTween.data != null && this.updateTween.isPlaying) {
			this.updateTween.updateTo('value', newNumber);
		} else {
			
		}*/
	}
}
