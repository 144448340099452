import { Address, beginCell, Cell, Contract, contractAddress, ContractProvider, Sender, SendMode, Dictionary, toNano, TupleBuilder } from '@ton/core';

export type RoosterBattleConfig = {};

export function roosterBattleConfigToCell(config: RoosterBattleConfig): Cell {
	return beginCell().endCell();
}

export class RoosterBattle implements Contract {
	constructor(readonly address: Address, readonly init?: { code: Cell; data: Cell }) {}

	/**
	 * Prepare deploy parameters for deployment
	 *
	 * @param code
	 * @param owner
	 * @returns RoosterBattle contract
	 */
	static createForDeploy(
		code: Cell,
		owner: Address
	): RoosterBattle {
		const dict = Dictionary.empty();
		const data = beginCell()
		.storeUint(0, 64)
		.storeAddress(owner)
		.storeDict(dict)
		.endCell();

		const workchain = 0; // deploy to workchain 0
		const address = contractAddress(workchain, { code, data });
		return new RoosterBattle(address, { code, data });
	}

	static createFromAddress(address: Address) {
		return new RoosterBattle(address);
	} 

	static createFromConfig(config: RoosterBattleConfig, code: Cell, workchain = 0) {
		const data = roosterBattleConfigToCell(config);
		const init = { code, data };
		return new RoosterBattle(contractAddress(workchain, init), init);
	}

	async sendDeploy(provider: ContractProvider, via: Sender, value: bigint) {
		await provider.internal(via, {
			value,
			sendMode: SendMode.PAY_GAS_SEPARATELY,
			body: beginCell().endCell(),
		});
	}

	/**
	 * User check-in
	 * @param provider 
	 * @param via Sender
	 * @returns Promise<SendMessageResult & {result:void}>
	 */
	async sendCheckin(provider: ContractProvider, via: Sender, amount: number){
		const messageBody = beginCell()
		.storeUint(2, 32) // op (op #2 = check in)
		.storeUint(0, 64) // query id
		.endCell();
		
		return await provider.internal(via, {
			value: toNano(amount), // send 0.2, receive back 0.1 - fee
			body: messageBody,
		});
	}

	async sendMoney(provider: ContractProvider, via: Sender, amount: number) {
		const body = beginCell()
		.storeUint(1000, 32) // op (op #2 = check in)
		.storeUint(0, 64) // query id
		.endCell();

		// send initial coins to contract
		await provider.internal(via, {
			value: toNano(amount),
			body: body,
		});
	}

	async getCount(provider: ContractProvider){
		const { stack } = await provider.get("info", []);
		return stack.readNumber();
	}

	async getIsCheckedIn(provider: ContractProvider, user: Address){
		var tuple = new TupleBuilder();
		tuple.writeAddress(user);

		const { stack } = await provider.get("is_checked_in", tuple.build());
		return stack.readNumber() ? true : false;
	}
}