import AppController from '../controller/AppController';
export default class TutorialHand extends Phaser.GameObjects.Container {
	constructor(scene, x, y) {
		super(scene, x, y);
		scene.add.existing(this);

		var tutorialHand = scene.add.image(0, 0, 'main2', 'hand_tut').setOrigin(0, 0);
		this.add(tutorialHand);

		/*
		this.radius = 20;
		
		var tween = this.tweens.add({
			targets: icon,
			rotation: +3,
		
			ease: 'Linear', // 'Cubic', 'Elastic', 'Bounce', 'Back'
		
			repeat: -1, // -1: infinity
			yoyo: false,
		
			// interpolation: null,
		});
		*/

		let tween = this.scene.tweens.add({
			targets: tutorialHand,
			x: +50,
			y: +50,
			ease: 'Linear',
			onUpdate: () => {},
			duration: 600,
			loop: -1,
			yoyo: true,
		});
	}
}
