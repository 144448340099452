import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

import * as humanizeDuration from 'humanize-duration';

export default class AirdropPopup extends Phaser.Scene {
	constructor() {
		super('AirdropPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'AirdropPopup' });
	}

	async init(data) {
		console.log('Airdrop Data', data);

		this.popupData = data;
	}

	preload() {}

	async create() {
		this.pawAirdropPrice = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 600, 800, 40, 40, 40, 50);
		var row_bg = this.add.nineslice(popupBG.x, popupBG.y, 'main2', 'popup_row_bg', popupBG.width - 55, popupBG.height - 55, 31, 5, 7, 32);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Airdrop Bonus', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			clearInterval(this.scene.interval);
			this.scene.scene.stop('AirdropPopup');
		});

		var bigbox = this.add.image(popupBG.x, popupBG.y - 190, 'main2', 'airdrop_thumb');

		this.button4 = new PopupButton(this, popupBG.x - 170, popupBG.y + 250, 'button_orange', '+4 Equip', 130);
		var button4Detail = this.add.text(this.button4.x, this.button4.y - 60, ['Random', 'Rarity'], AppController.getText(24)).setOrigin(0.5, 0.5);
		var button4BgPrice = this.add.nineslice(this.button4.x, this.button4.y + 65, 'main2', 'airdrop_price_bg', 100, 0, 24, 24);
		var button4PriceText = this.add.text(button4BgPrice.x, button4BgPrice.y, 'Free', AppController.getText(22)).setOrigin(0.5, 0.5);

		this.button8 = new PopupButton(this, popupBG.x, popupBG.y + 250, 'button_blue', '+8 Equip', 130);
		var button8Detail = this.add.text(this.button8.x, this.button8.y - 100, ['100%', 'Guaranteed'], AppController.getText(24)).setOrigin(0.5, 0.5);
		var button8Detail2 = this.add.text(this.button8.x, this.button8.y - 55, '+4 Rare', AppController.getText(28, '#1da8fa', 'bold')).setOrigin(0.5, 0.5);
		var button8BgPrice = this.add.nineslice(this.button8.x, this.button8.y + 65, 'main2', 'airdrop_price_bg', 120, 0, 24, 24);
		var button8PriceText = this.add.text(button8BgPrice.x + 5, button8BgPrice.y, 'Checkin', AppController.getText(22)).setOrigin(0.5, 0.5);
		var tonIcon = this.add.image(button8BgPrice.x - 50, button8BgPrice.y, 'main2', 'airdrop_price_ton');

		this.button12 = new PopupButton(this, popupBG.x + 170, popupBG.y + 250, 'button_green', '+12 Equip', 130);
		var button12Detail = this.add.text(this.button12.x, this.button12.y - 100, ['100%', 'Guaranteed'], AppController.getText(24)).setOrigin(0.5, 0.5);

		this.leftTimeText = this.add.text(bigbox.x, bigbox.y + 260, '', AppController.getText(22)).setOrigin(0.5, 1);

		var button12BgPrice = this.add.nineslice(this.button12.x, this.button12.y + 65, 'main2', 'airdrop_price_bg', 120, 0, 24, 24);
		this.button12PriceText = this.add.text(button12BgPrice.x + 5, button12BgPrice.y, '', AppController.getText(22)).setOrigin(0.5, 0.5);
		var tonIcon = this.add.image(this.button12PriceText.x - 50, this.button12PriceText.y, 'main2', 'airdrop_price_paw');

		var resetData = await this.resetAirdropData();

		/*if (resetData.data.time) {
			var leftTime = this.add.text(bigbox.x, bigbox.y + 260, ['Refresh after:', humanizeDuration(resetData.data.time * 1000)],AppController.getText(22)).setOrigin(0.5, 1);
		}

		if (resetData.data.nextReset) {
			const now = new Date();
			const diffSecond = resetData.data.nextReset - Math.floor(now.getTime() / 1000);

			var leftTime = this.add.text(bigbox.x, bigbox.y + 260, ['Refresh after:', humanizeDuration(diffSecond * 1000)],AppController.getText(22)).setOrigin(0.5, 1);
		}*/

		this.button8.on('pointerup', async function() {
			this.isLoading = true;
			this.setText('Loading...');
			this.disableBtn = true;
			r1.removeInteractive();
			this.scene.button4.removeInteractive();
			this.scene.button12.removeInteractive();

			var checkinResult = await AppController.checkInTon();

			console.log(checkinResult);

			if (checkinResult.success == false) {
				clearInterval(this.scene.interval);
				this.scene.scene.stop('AirdropPopup');
				this.scene.scene.launch('Alert', { mess: 'Transaction not sent!' });
				return;
			}

			const returnData = await AppController.claimAirdrop(8);
			if (returnData.data.success == false) {
				clearInterval(this.scene.interval);
				this.scene.scene.launch('Alert', { mess: 'Error' });
				this.scene.scene.stop('AirdropPopup');
				return;
			}
			AppController.callLog('get_airdrop_8');
			if (returnData.data.array != null && returnData.data.array.length > 0) {
				var remainingFree = returnData.data.array.length;

				console.log('ahihu');

				for (var i = 0; i < returnData.data.array.length; i++) {
					if (returnData.data.array[i] != 0) {
						break;
					}
					remainingFree -= 1;
				}
				this.scene.scene.get('MainScene').mutantPriceText.text = 'Free (' + remainingFree + '/' + returnData.data.array.length + ')';
				this.scene.scene.get('MainScene').glowMutantButton();
			}
			clearInterval(this.scene.interval);
			this.scene.scene.stop('AirdropPopup');
		});

		this.button4.on('pointerup', async function() {
			this.isLoading = true;
			this.setText('Loading...');
			this.disableBtn = true;
			r1.removeInteractive();
			this.scene.button8.removeInteractive();
			this.scene.button12.removeInteractive();

			const returnData = await AppController.claimAirdrop(4);
			if (returnData.data.success == false) {
				this.scene.scene.launch('Alert', { mess: 'Error' });
				this.scene.scene.stop('AirdropPopup');
				return;
			}
			AppController.callLog('get_airdrop_4');
			if (returnData.data.array != null && returnData.data.array.length > 0) {
				var remainingFree = returnData.data.array.length;

				console.log('ahihu');

				for (var i = 0; i < returnData.data.array.length; i++) {
					if (returnData.data.array[i] != 0) {
						break;
					}
					remainingFree -= 1;
				}
				this.scene.scene.get('MainScene').mutantPriceText.text = 'Free (' + remainingFree + '/' + returnData.data.array.length + ')';
				this.scene.scene.get('MainScene').glowMutantButton();
			}
			clearInterval(this.scene.interval);
			this.scene.scene.stop('AirdropPopup');
		});

		this.button12.on('pointerup', async function() {
			this.isLoading = true;
			this.setText('Loading...');
			this.disableBtn = true;
			r1.removeInteractive();
			this.scene.button8.removeInteractive();
			this.scene.button4.removeInteractive();

			const returnData = await AppController.claimAirdrop(12);
			if (returnData.data.success == false) {
				this.scene.scene.launch('Alert', { mess: 'You dont have enough Paw' });
				this.scene.scene.stop('AirdropPopup');
				return;
			}
			AppController.callLog('get_airdrop_12');
			this.scene.scene.get('MainScene').pawSlot.updateNumber(this.scene.popupData.displayPaw - this.scene.pawAirdropPrice);
			console.log(returnData);

			if (returnData.data.array != null && returnData.data.array.length > 0) {
				var remainingFree = returnData.data.array.length;

				console.log('ahihu');

				for (var i = 0; i < returnData.data.array.length; i++) {
					if (returnData.data.array[i] != 0) {
						break;
					}
					remainingFree -= 1;
				}
				this.scene.scene.get('MainScene').mutantPriceText.text = 'Free (' + remainingFree + '/' + returnData.data.array.length + ')';
				this.scene.scene.get('MainScene').glowMutantButton();
			}
			clearInterval(this.scene.interval);
			this.scene.scene.stop('AirdropPopup');
		});

		//
	}

	async resetAirdropData() {
		AppController.callLog('open_airdrop');

		if (this.button12Detail2) {
			this.button12Detail2.destroy();
		}
		this.scene.launch('LockScreen');
		const resetData = await AppController.resetAirdrop();
		console.log('resetData', resetData);
		this.button12PriceText.text = resetData.data.pawAirdropPrice;
		this.pawAirdropPrice = resetData.data.pawAirdropPrice;
		this.scene.stop('LockScreen');

		var leftTimeNumber = 0;

		if (resetData.data.time) {
			leftTimeNumber = resetData.data.time;
		}

		if (resetData.data.claimedAirdrop == true) {
			this.button4.disableBtn = true;
			this.button8.disableBtn = true;
			this.button12.disableBtn = true;
		}

		var pawAirdropArr = [];

		if (this.popupData.pawAirdrop != null) {
			pawAirdropArr = this.popupData.pawAirdrop;
		}

		if (resetData.data.pawAirdrop != null) {
			pawAirdropArr = resetData.data.pawAirdrop;
			this.scene.get('MainScene').myCharacterData.pawAirdrop = pawAirdropArr;
		}

		if (pawAirdropArr.length == 1) {
			this.button12Detail2 = this.add.text(this.button12.x, this.button12.y - 55, '+1 Legendary', AppController.getText(28, '#fff200', 'bold')).setOrigin(0.5, 0.5);
		}
		if (pawAirdropArr.length == 4) {
			this.button12Detail2 = this.add.text(this.button12.x, this.button12.y - 55, '+4 Epic', AppController.getText(28, '#fe0cfe', 'bold')).setOrigin(0.5, 0.5);
		}
		if (pawAirdropArr.length == 8) {
			this.button12Detail2 = this.add.text(this.button12.x, this.button12.y - 55, '+8 Rare', AppController.getText(28, '#00a7fd', 'bold')).setOrigin(0.5, 0.5);
		}

		if (resetData.data.nextReset) {
			const now = new Date();
			leftTimeNumber = resetData.data.nextReset - Math.floor(now.getTime() / 1000);
		}
		var txt = this.leftTimeText;
		var scene = this;
		this.interval = setInterval(async function() {
			txt.text = ['Refresh after:', humanizeDuration(leftTimeNumber * 1000)];
			leftTimeNumber -= 1;
			if (leftTimeNumber < 0) {
				clearInterval(scene.interval);
				//console.log(this);

				//await scene.resetAirdropData();
			}
		}, 1000);
		return resetData;
	}
}
