import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import IconButton from '../components/IconButton';
import ItemStatRow from '../components/ItemStatRow';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class SettingPopup extends Phaser.Scene {
	constructor() {
		super('SettingPopup');
	}
	static soundOn = true;

	initialize() {
		Phaser.Scene.call(this, { key: 'SettingPopup' });
	}

	async init(data) {
		console.log(data);

		this.itemData = data;
	}

	preload() {}

	create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 550, 480, 40, 40, 40, 50);
		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Setting', AppController.getText(40)).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('SettingPopup');
		});

		var soundBtn = new SimpleImageButton(this, popupBG.x, popupBG.y - 50, 'bt_sound_on', 'bt_sound_off');
		soundBtn.selected = SettingPopup.soundOn;
		var soundTxt = this.add.text(soundBtn.x, soundBtn.y + 70, 'Sound: On', AppController.getText(26)).setOrigin(0.5, 0.5);
		soundTxt.text = soundBtn.isSelected ? 'Sound: On' : 'Sound: Off';

		soundBtn.on('pointerup', function() {
			console.log(this.isSelected);
			SettingPopup.soundOn = this.isSelected;

			console.log('thisahi', this);

			AppController.gameObj.sound.mute = !this.isSelected;
			soundTxt.text = this.isSelected ? 'Sound: On' : 'Sound: Off';
		});

		var userIdButton = new IconButton(this, soundBtn.x, soundBtn.y + 170, 'earn_button', 'User ID: ', 'bt_copy', 350);
		userIdButton.setText('User ID: ' + AppController.getUserId());

		userIdButton.on('pointerup', async function() {
			await navigator.clipboard.writeText(AppController.getUserId());

			this.setText('User ID copied to clipboard!');
		});

		var versionTxt = this.add.text(userIdButton.x, userIdButton.y + 70, 'V.' + AppController.getVersion(), AppController.getText(18)).setOrigin(0.5, 0.5);

		//var userIdText = this.add.text(soundBtn.x, soundBtn.y + 170, 'User ID: ' + getUserId(),AppController.getText(40)).setOrigin(0.5, 0.5);
		//var copyButton = new SimpleImageButton(this, userIdText.x + userIdText.width / 2 + 20, userIdText.y, 'bt_copy');
	}
}
