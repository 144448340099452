import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import AppController from '../controller/AppController';
export default class LockScreen extends Phaser.Scene {
	constructor() {
		super('LockScreen');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'LockScreen' });
	}

	async init(data) {}

	preload() {}

	async create() {
		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		r1.on('pointerup', function() {});

		var iconBg = this.add.image(r1.x, r1.y, 'main2', 'loading_bg').setOrigin(0.5, 0.5);
		var icon = this.add.image(r1.x, r1.y, 'main2', 'loading_status').setOrigin(0.5, 0.5);

		var tween = this.tweens.add({
			targets: icon,
			rotation: +3,

			ease: 'Linear', // 'Cubic', 'Elastic', 'Bounce', 'Back'

			repeat: -1, // -1: infinity
			yoyo: false,

			// interpolation: null,
		});

		var label = this.add.text(r1.x, r1.y + icon.height, 'Loading...', AppController.getText(40)).setOrigin(0.5, 0.5);
	}
}
