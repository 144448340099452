import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import IconButton from '../components/IconButton';
import ItemStatRow from '../components/ItemStatRow';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class EnhancedEquipPopup extends Phaser.Scene {
	constructor() {
		super('EnhancedEquipPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'EnhancedEquipPopup' });
	}

	async init(data) {
		console.log(data);

		this.itemData = data.item;
		this.enhanceList = data.enhanceList;

		if (data.item.position == 'head') {
			this.slotName = 'Head Slot';
		}
		if (data.item.position == 'body') {
			this.slotName = 'Body Slot';
		}
		if (data.item.position == 'leg') {
			this.slotName = 'Leg Slot';
		}
		if (data.item.position == 'hand_left') {
			this.slotName = 'Left Hand Slot';
		}
		if (data.item.position == 'hand_right') {
			this.slotName = 'Right Hand Slot';
		}
	}

	preload() {}

	create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		this.popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 550, 700, 40, 40, 40, 50);
		var titleBg = this.add.nineslice(this.popupBG.x, this.popupBG.y - this.popupBG.height / 2, 'main2', 'popup_title_bg', this.popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Enhance ' + this.slotName, AppController.getText(30)).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('EnhancedEquipPopup');
		});

		var updown = [0, 0, 0];

		var enhanceLevel = 0;

		if (this.itemData.enhancedLevel != null && this.itemData.enhancedLevel > 0) {
			enhanceLevel = this.itemData.enhancedLevel;
		}

		this.createRow(enhanceLevel);

		if (enhanceLevel < this.enhanceList.length) {
			var currentLevelInfo = this.enhanceList[enhanceLevel];

			var description = this.add.text(this.popupBG.x, this.popupBG.y + 100, 'Permanent upgrades for ' + this.slotName /* + ': (+' + currentLevelInfo.bonus_percent + '%) equipment stats'*/, AppController.getText(22)).setOrigin(0.5, 0.5);

			var displayRate = currentLevelInfo.display_rate;
			if (this.itemData.displayRate != null && this.itemData.displayRate > 0) {
				displayRate = this.itemData.displayRate;
			}

			var enhanceBtn = new PopupButton(this, this.popupBG.x, this.popupBG.y + 200, 'button_green', '    ' + currentLevelInfo.cost, 150);

			var earn_icon = this.add.image(enhanceBtn.x - 30, enhanceBtn.y, 'main2', 'paw_token_1').setOrigin(0.5, 0.5);
			earn_icon.setScale(0.5, 0.5);

			if (this.scene.get('MainScene').myCharacterData.displayPaw < currentLevelInfo.cost) {
				enhanceBtn.disableBtn = true;
			}

			var description2 = this.add.text(enhanceBtn.x, enhanceBtn.y + 70, 'Enhance success change: ' + displayRate + '%', AppController.getText(24)).setOrigin(0.5, 0.5);

			enhanceBtn.on('pointerup', async function() {
				this.scene.scene.launch('LockScreen');
				const returnData = await AppController.enhancePosition(this.scene.itemData.position);

				if (returnData.data.success == false) {
					this.scene.scene.stop('LockScreen');
					this.scene.scene.launch('Alert', returnData.data);
					return;
				}

				if (returnData.data.enhanceSuccess == true) {
					AppController.callLog('enhance_success', enhanceLevel);
					if (enhanceLevel == this.scene.enhanceList.length - 1) {
						this.scene.scene.launch('EnhanceResultPopup', { isWin: true });
						this.scene.scene.stop('EnhancedEquipPopup');
						this.scene.scene.stop('LockScreen');
						this.scene.scene.get('MainScene').resetBattleField();
						await this.scene.scene.get('MainScene').loadPlayerData();
						return;
					}
					enhanceLevel += 1;
					this.scene.createRow(enhanceLevel);
					description.text = 'Permanent upgrades for ' + this.scene.slotName; // + ': (+' + returnData.data.nextLevel.bonus_percent + '%) equipment stats';
					description2.text = 'Enhance success change: ' + returnData.data.nextLevel.display_rate + '%';
					enhanceBtn.setText(returnData.data.nextLevel.cost);
					this.scene.scene.get('MainScene').resetBattleField();
					await this.scene.scene.get('MainScene').loadPlayerData();
					this.scene.scene.launch('EnhanceResultPopup', { isWin: true });
				} else {
					AppController.callLog('enhance_failed', enhanceLevel);
					displayRate = returnData.data.newRate;
					description2.text = 'Enhance success change: ' + displayRate + '%';

					this.scene.scene.launch('EnhanceResultPopup', { isWin: false });
				}

				this.scene.scene.stop('LockScreen');
			});
		}
	}

	createRow(enhanceLevel) {
		var color = ['gray', 'green', 'blue', 'purple', 'yellow'];
		var rarity = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];
		if (this.row1 != null) {
			this.row1.destroy();
		}
		this.row1 = new ItemStatRow(this, this.popupBG.x, this.popupBG.y - 100, '[' + rarity[this.itemData.rarity - 1] + '] ' + this.itemData.name, this.itemData.position + '_' + this.itemData.image, color[this.itemData.rarity - 1], { lv: this.itemData.level, atk: this.itemData.atk, def: this.itemData.def, hp: this.itemData.hp }, null, enhanceLevel, true);
	}
}
