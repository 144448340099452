import AppController from '../controller/AppController';
export default class AttackButtonInfo extends Phaser.GameObjects.Container {
	constructor(scene, x, y, icon, name, number, atlas = 'main2') {
		super(scene, x, y);
		scene.add.existing(this);
		this.bg = scene.add.image(0, 0, 'main2', 'skill_bg');
		this.icon = scene.add.image(0, 0, 'main2', icon);
		this.atkIcon = scene.add.image(this.icon.x + this.icon.width - 20, this.icon.y, 'main2', 'stat_atk').setOrigin(0, 0);
		this.txtName = scene.add.text(this.icon.x + this.icon.width - 20, 0 - this.atkIcon.height / 2, name, AppController.getText(28)).setOrigin(0, 1);
		this.atkLabel = scene.add.text(this.atkIcon.x + this.atkIcon.width + 10, this.atkIcon.y - 7, 'ATK', AppController.getText(30)).setOrigin(0, 0);
		this.atkNumber = scene.add.text(this.atkLabel.x, this.atkLabel.y + 30, number, AppController.getText(45)).setOrigin(0, 0);
		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.icon);
		this.add(this.atkIcon);
		this.add(this.txtName);
		this.add(this.atkLabel);
		this.add(this.atkNumber);
	}

	setInfo(name, thumb, atk) {
		this.txtName.text = name;
		this.atkNumber.text = atk;
		this.icon.setTexture('main2', thumb);
	}
}
