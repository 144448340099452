import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import AttackButtonInfo from '../components/AttackButtonInfo';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class CharacterInfoPopup extends Phaser.Scene {
	constructor() {
		super('CharacterInfoPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'CharacterInfoPopup' });
	}

	async init(data) {
		console.log(data);
		this.characterData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 900, 40, 40, 40, 50);
		popupBG.setInteractive();
		var row_bg = this.add.nineslice(popupBG.x, popupBG.y, 'main2', 'popup_row_bg', popupBG.width - 60, popupBG.height - 60, 31, 5, 7, 32);
		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Character Info', AppController.getText(50)).setOrigin(0.5, 0.5);
		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			clearInterval(this.scene.interval);
			this.scene.scene.stop('CharacterInfoPopup');
		});
		var charNameTxt = this.add.text(titleBg.x, titleBg.y + 100, this.characterData.character.name, AppController.getText(40)).setOrigin(0.5, 0.5);
		var charAvatarBg = this.add.image(charNameTxt.x, charNameTxt.y + 100, 'main2', 'character_rarity_common_bg');
		var charAvatar = this.add.image(charNameTxt.x, charNameTxt.y + 100, 'main2', 'character_' + this.characterData.skin);

		var currentStatTitle = this.add.text(charAvatar.x, charAvatar.y + 100, 'Current Stats', AppController.getText(26)).setOrigin(0.5, 0.5);
		this.currentStatBg = this.add.nineslice(currentStatTitle.x, currentStatTitle.y + 90, 'main2', 'currency_bg', row_bg.width - 50, 130, 5, 5, 5, 5);

		this.createStat([this.calculateNewStats(this.characterData, 'atk'), this.calculateNewStats(this.characterData, 'def'), this.calculateNewStats(this.characterData, 'hp')]);

		var skillsTitle = this.add.text(currentStatTitle.x, currentStatTitle.y + 200, 'Attack', AppController.getText(26)).setOrigin(0.5, 0.5);

		var skillBg = this.add.nineslice(skillsTitle.x, skillsTitle.y + 190, 'main2', 'currency_bg', row_bg.width - 50, 320, 5, 5, 5, 5);

		var normalAtkNumber = Math.floor((this.calculateNewStats(this.characterData, 'atk') * this.characterData.character.normal_attack.atk_percent) / 100);

		normalAtkNumber = AppController.formatNumber(normalAtkNumber) + ' (' + this.characterData.character.normal_attack.atk_percent + '%)';

		var atkNumber = Math.floor((this.calculateNewStats(this.characterData, 'atk') * this.characterData.character.skill_attack.atk_percent) / 100);
		atkNumber = AppController.formatNumber(atkNumber) + ' (' + this.characterData.character.skill_attack.atk_percent + '%)';

		this.normalAtkInfo = new AttackButtonInfo(this, 170, skillBg.y - 90, this.characterData.character.normal_attack.thumb, this.characterData.character.normal_attack.name, normalAtkNumber);

		this.skillInfo = new AttackButtonInfo(this, 170, skillBg.y + 60, this.characterData.character.skill_attack.thumb, this.characterData.character.skill_attack.name, atkNumber);
	}

	createStat(valueArr) {
		var labelArr = ['atk', 'def', 'hp'];
		this.statsText = [];

		for (var i = 0; i < valueArr.length; i++) {
			const atkIcon = this.add.image(160 + 150 * i, this.currentStatBg.y - 40, 'main2', 'stat_' + labelArr[i]).setOrigin(0.5, 0);
			const atkText = this.add.text(atkIcon.x + atkIcon.width - 10, atkIcon.y, labelArr[i].toUpperCase(), AppController.getText(30)).setOrigin(0, 0);
			this.statsText[labelArr[i]] = this.add.text(atkText.x, atkText.y + 30, AppController.formatNumber(valueArr[i]), AppController.getText(50)).setOrigin(0, 0);
		}
	}

	calculateNewStats(playerObj, statName) {
		var value = 0;
		value += playerObj.character[statName];

		if (playerObj.equipments) {
			for (var key in playerObj.equipments) {
				if (playerObj.equipments.hasOwnProperty(key)) {
					value += playerObj.equipments[key][statName];
				}
			}
		}
		return value;
	}
}
