// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, signInWithCustomToken, TwitterAuthProvider, linkWithPopup, linkWithRedirect, updateProfile } from 'firebase/auth';
import { getDatabase, ref, onValue, push, onDisconnect, set, serverTimestamp } from 'firebase/database';
import { RoosterBattle } from '../contracts/wrapper/DogMutantWrapper';

import { Address, OpenedContract } from '@ton/core';
import { beginCell, toNano, TonClient } from '@ton/ton';

import axios from 'axios';
let instance;
let counter = 0;
var _enhanceLevelList = [];
var bossAnnoucement = [];
var _gameObj;

const firebaseConfig = {
	apiKey: 'AIzaSyCyUz2g1TuunmtR9dCZfCBIVaR1tHiOIck',
	authDomain: 'dog-mutant.firebaseapp.com',
	projectId: 'dog-mutant',
	storageBucket: 'dog-mutant.appspot.com',
	messagingSenderId: '295690567270',
	appId: '1:295690567270:web:efe6494d13213c7a17b7a7',
	measurementId: 'G-YC13HQXJ3J',
	databaseURL: 'https://dog-mutant-default-rtdb.asia-southeast1.firebasedatabase.app',
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const db = getDatabase();
const auth = getAuth();
var myConnectionsRef;
var lastOnlineRef;
var twitterRef;
var pawRef;
var alertRef;
var configRef;

var tonConnectUI;

class AppController {
	constructor() {
		if (instance) {
			throw new Error('You can only create one instance!');
		}
		instance = this;

		var isMaintain = false;
		var maintainTime = 0;

		this.updateMaintain = (inputIsMaintain, inputMaintainTime) => {
			isMaintain = inputIsMaintain;
			console.log('isMaintain', isMaintain);
			maintainTime = inputMaintainTime;
		};

		this.getBossAnnoucement = () => {
			return bossAnnoucement;
		};

		this.getMaintain = () => {
			if (isMaintain) {
				return { maintain: true, maintainTime: maintainTime };
			} else {
				return { maintain: false };
			}
		};
	}

	getUserId() {
		if (Telegram.WebApp.initDataUnsafe.user != null) return Telegram.WebApp.initDataUnsafe.user.id;
		else {
			return '5095490435';
		}
	}

	set gameObj(value) {
		_gameObj = value;
	}

	get gameObj() {
		return _gameObj;
	}

	set enhanceLevelList(value) {
		_enhanceLevelList = value;
	}
	get enhanceLevelList() {
		return _enhanceLevelList;
	}

	get currentFriendlyAddress() {
		if (tonConnectUI.walletInfo == null || tonConnectUI.connected == false) {
			return 'Not Connected';
		} else {
			const address1 = TON_CONNECT_UI.toUserFriendlyAddress(tonConnectUI.account.address);
			return address1;
		}
	}

	async getData() {
		console.log('initData', Telegram.WebApp.initDataUnsafe);

		tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
			manifestUrl: 'https://webapp2.dogmutant.com/tonconnect-manifest.json',
		});
		console.log(tonConnectUI);

		/*tonConnectUI = new TonConnectUI({
			manifestUrl: 'https://webapp2.dogmutant.com/tonconnect-manifest.json',
		});*/

		const unsubscribeModal = tonConnectUI.onModalStateChange(state => {
			console.log(state);

			if (state.status == 'closed') {
				console.log('ahi');
				this.stopScroll();
			}
		});

		const response = await axios.post(
			'https://helloworld-t75wjesv6a-uc.a.run.app?uid=&data=' + btoa(Telegram.WebApp.initData),
			//'https://local.dogmutant.com/dog-mutant/us-central1/helloWorld?uid=6621001666&data=' + btoa(Telegram.WebApp.initData),
			{
				firstName: 'Fred',
				lastName: 'Flintstone',
				authenData: Telegram.WebApp.initData,
			},
			{
				headers: {
					'authen-data': Telegram.WebApp.initData,
				},
			}
		);
		const token = response.data;

		const userCredential = await signInWithCustomToken(auth, token);

		console.log(userCredential);

		configRef = ref(db, 'config');
		myConnectionsRef = ref(db, 'users/' + auth.currentUser.uid + '/connections');
		lastOnlineRef = ref(db, 'users/' + auth.currentUser.uid + '/lastOnline');
		twitterRef = ref(db, 'users/' + auth.currentUser.uid + '/twitter');
		pawRef = ref(db, 'users/' + auth.currentUser.uid + '/paw');
		alertRef = ref(db, 'users/' + auth.currentUser.uid + '/alert');

		if (Telegram.WebApp.initData.length == 0) {
		} else {
			await updateProfile(auth.currentUser, {
				displayName: Telegram.WebApp.initDataUnsafe.user.first_name + ' ' + Telegram.WebApp.initDataUnsafe.user.last_name,
			});
		}

		logEvent(analytics, 'open_app');

		const connectedRef = ref(db, '.info/connected');

		onValue(twitterRef, snapshot => {
			const twitterData = snapshot.val();
			event = new CustomEvent('twitterData', {
				detail: twitterData,
			});

			window.dispatchEvent(event);
		});

		onValue(configRef, snapshot => {
			const configData = snapshot.val();
			console.log('configData', configData);
			this.updateMaintain(configData.maintain, configData.maintain_time);

			var newArr = [];
			Object.keys(configData.boss_annoucement).forEach(e => {
				newArr.push(configData.boss_annoucement[e]);
			});
			bossAnnoucement = newArr;

			event = new CustomEvent('maintain', {
				detail: configData,
			});

			window.dispatchEvent(event);
		});

		onValue(alertRef, snapshot => {
			const mess = snapshot.val();
			event = new CustomEvent('alert', {
				detail: mess,
			});

			window.dispatchEvent(event);
		});

		onValue(pawRef, snapshot => {
			const paw = snapshot.val();
			event = new CustomEvent('pawEvent', {
				detail: paw,
			});

			window.dispatchEvent(event);
		});

		onValue(connectedRef, snap => {
			if (snap.val() === true) {
				event = new CustomEvent('backOnline', {
					detail: {
						action: 'didInitialize',
						payload: 3,
					},
				});

				window.dispatchEvent(event);

				// We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
				const con = push(myConnectionsRef);

				// When I disconnect, remove this device
				onDisconnect(con).remove();

				// Add this device to my connections list
				// this value could contain info about the device or a timestamp too
				set(con, true);

				// When I disconnect, update the last time I was seen online
				onDisconnect(lastOnlineRef).set(serverTimestamp());
			} else {
				event = new CustomEvent('goOffline', {
					detail: {
						action: 'didInitialize',
						payload: 3,
					},
				});

				window.dispatchEvent(event);
			}
		});

		return userCredential.user;
	}

	clearAlert() {
		set(alertRef, false);
	}

	async startNewBattle() {
		logEvent(analytics, 'start_new_battle');
		const startNewBattle = httpsCallable(functions, 'startNewBattle');
		const result = await startNewBattle();
		fbq('trackCustom', 'startNewBattle');

		console.log(result.data);
		return result.data;
	}

	async startNewBossFight() {
		logEvent(analytics, 'start_new_boss_fight');
		const startNewBossFight = httpsCallable(functions, 'startNewBossFight');
		const result = await startNewBossFight();
		fbq('trackCustom', 'startNewBossFight');

		console.log(result.data);
		return result.data;
	}

	async getMyReferral() {
		const myReferral = httpsCallable(functions, 'myReferral');
		const result = await myReferral();
		//console.log(result.data);
		return result.data;
	}
	async getMyFriendsList() {
		const getMyFriendsList = httpsCallable(functions, 'getMyFriendsList');
		const result = await getMyFriendsList();
		//console.log(result.data);
		return result.data;
	}

	async getMyReferralLink() {
		const getMyRefLink = httpsCallable(functions, 'getMyRefLink');
		const result = await getMyRefLink();
		//console.log(result.data);
		return result.data;
	}

	async getMyCharacter() {
		const getMyCharacter = httpsCallable(functions, 'getMyCharacter');
		const result = await getMyCharacter();
		//console.log(result.data.);
		return result.data;
	}

	async summonNewItem() {
		const summonItem = httpsCallable(functions, 'summonNewItem');
		const result = await summonItem();

		const machineObj = result.data.data.machine;
		logEvent(analytics, 'summon_item', { machineLevel: machineObj.level });
		fbq('trackCustom', 'summonItem', machineObj);

		return result.data;
	}

	async getTasks() {
		const getTasks = httpsCallable(functions, 'getTasks');
		const result = await getTasks();
		//console.log(result.data);
		return result.data;
	}

	getVersion() {
		return '0226-091124';
	}

	async buyInappProduct(inAppId, secret) {
		logEvent(analytics, 'buy_inapp_' + inAppId);
		const buyProduct = httpsCallable(functions, 'buyProduct');
		const result = await buyProduct({ inAppId: inAppId, secret: secret });
		return result.data;
	}

	async powerUpCharacter() {
		const powerUpCharacter = httpsCallable(functions, 'powerUpCharacter');

		const result = await powerUpCharacter();

		return result.data;
	}

	async doTask(taskId) {
		logEvent(analytics, 'do_task', {
			task_id: taskId,
		});

		fbq('trackCustom', 'doTask', {
			task_id: taskId,
		});

		const doTask = httpsCallable(functions, 'doTask');
		const result = await doTask({ task_id: taskId });
		return result.data;
	}

	async equipPendingItemNew() {
		logEvent(analytics, 'equip_item');
		const equipPendingItem = httpsCallable(functions, 'equipPendingItemNew');
		const result = await equipPendingItem();
		console.log(result.data);
		return result.data;
	}

	async getLeaderboard() {
		logEvent(analytics, 'get_leaderboard');
		const getLeaderboard = httpsCallable(functions, 'getLeaderboard');
		const result = await getLeaderboard();
		console.log(result.data);
		return result.data;
	}

	async getBossLeaderboard() {
		logEvent(analytics, 'get_boss_leaderboard');
		const getBossLeaderboard = httpsCallable(functions, 'getBossLeaderboard');
		const result = await getBossLeaderboard();
		console.log(result.data);
		return result.data;
	}

	async getRankLeaderboard(rank) {
		logEvent(analytics, 'get_rank_leaderboard');
		const getRankLeaderboard = httpsCallable(functions, 'getRankLeaderboard');
		const result = await getRankLeaderboard({ rank: rank });
		console.log(result.data);
		return result.data;
	}

	async openInvoice(url) {
		console.log('openInvoice');
		Telegram.WebApp.openInvoice(url);
	}

	async resetAirdrop() {
		const resetAirdrop = httpsCallable(functions, 'resetAirdrop');
		const result = await resetAirdrop();
		console.log(result.data);
		return result.data;
	}

	async claimAirdrop(airdropNumber) {
		const claimAirdrop = httpsCallable(functions, 'claimAirdrop');
		const result = await claimAirdrop({ airdropNumber: airdropNumber });
		console.log(result.data);
		return result.data;
	}

	async getOfflineReward(x3 = false) {
		const getOfflineReward = httpsCallable(functions, 'getOfflineReward');
		const result = await getOfflineReward({ x3: x3 });
		return result.data;
	}

	async enhancePosition(position) {
		console.log(position);
		const enhancePosition = httpsCallable(functions, 'enhancePosition');
		const result = await enhancePosition({ postion: position });
		console.log(result.data);
		return result.data;
	}

	async createTempPawTransaction(inappId) {
		const createTempPawTransaction = httpsCallable(functions, 'createTempPawTransaction');
		const result = await createTempPawTransaction({ inappId: inappId });
		console.log(result.data);
		return result.data;
	}

	async getMineList() {
		const getMineList = httpsCallable(functions, 'getMineList');
		const result = await getMineList();
		console.log(result.data);
		return result.data;
	}

	async levelUpMine(mineId) {
		const levelUpMine = httpsCallable(functions, 'levelUpMine');
		const result = await levelUpMine({ mineId: mineId });
		console.log(result.data);
		return result.data;
	}

	async getPayload() {
		const getPayload = httpsCallable(functions, 'getPayload');
		const result = await getPayload();
		console.log(result.data);
		return result.data;
	}

	async buyOffers(offerId) {
		const buyOffers = httpsCallable(functions, 'buyOffers');
		const result = await buyOffers({ offerId: offerId });
		console.log(result.data);
		return result.data;
	}

	async claimSeasonReward() {
		const claimSeasonReward = httpsCallable(functions, 'claimSeasonReward');
		const result = await claimSeasonReward();
		console.log(result.data);
		return result.data;
	}
	async verifyProof(connectedWallet) {
		const verifyProof = httpsCallable(functions, 'verifyProof');
		const result = await verifyProof({ connectedWallet: connectedWallet });
		console.log(result.data);
		return result.data;
	}

	async getInappProducts() {
		const getInappProducts = httpsCallable(functions, 'getInappProducts');
		const result = await getInappProducts();
		console.log(result.data);
		return result.data;
	}

	async dailyShopReward() {
		const dailyShopReward = httpsCallable(functions, 'dailyShopReward');
		const result = await dailyShopReward();
		console.log(result.data);
		return result.data;
	}

	async loadTonConnect() {
		if (tonConnectUI.walletInfo == null || tonConnectUI.connected == false) {
			this.allowScroll();
			try {
				const payload = await this.getPayload();

				console.log('getPayload:', payload);

				tonConnectUI.setConnectRequestParameters({
					state: 'ready',
					value: {
						tonProof: payload.payload,
					},
				});

				tonConnectUI.onStatusChange(wallet => {
					console.log('onchange', wallet);

					if (wallet && wallet.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
						console.log('Proof', wallet.connectItems.tonProof.proof);
					}
				});

				const connectedWallet = await tonConnectUI.connectWallet();
				// Do something with connectedWallet if needed

				console.log('connectedWallet: ', connectedWallet);
				const verifyResult = await this.verifyProof(connectedWallet);
				console.log('verifyResult', verifyResult);

				return true;
			} catch (e) {
				console.log(e);
			}
		} else {
			const resppnse = await tonConnectUI.disconnect();

			return;
		}
	}

	async checkInTon() {
		if (tonConnectUI.walletInfo == null || tonConnectUI.connected == false) {
			await this.loadTonConnect();
		}

		this.allowScroll();

		const client = new TonClient({
			endpoint: 'https://toncenter.com/api/v2/jsonRPC',
		});

		const contract = new RoosterBattle(Address.parse('EQBzYuxK0lP5Bl5T6VvL8OoHpRPtV55vYyXPK1Q1nCc4gyDw'));

		var openedContract = client.open(contract);

		///var result = await openedContract.sendCheckin(sender, 0.2);

		var address = openedContract?.address.toString();
		console.log(address);

		const body = beginCell()
			.storeUint(2, 32) // op (op #2 = check in)
			.storeUint(0, 64) // query id
			.endCell();

		const transaction = {
			validUntil: Math.floor(Date.now() / 1000) + 360,
			messages: [
				{
					address: address,
					amount: toNano(0.008).toString(),
					payload: body.toBoc().toString('base64'), // payload with comment in body
				},
			],
		};

		try {
			const result = await tonConnectUI.sendTransaction(transaction);

			// you can use signed boc to find the transaction
			//const someTxData = await myAppExplorerService.getTransaction(result.boc);
			//alert('Transaction was sent successfully', someTxData);
			console.log(result);
			console.log('result.boc', result.boc);
			return result;
			this.stopScroll();
			return { success: true, data: result };
		} catch (e) {
			console.log(e);
			this.stopScroll();
			return { success: false, error: e };
		}

		//
	}

	async sendMoneyTon(value, description) {
		if (tonConnectUI.walletInfo == null || tonConnectUI.connected == false) {
			await this.loadTonConnect();
		}

		this.allowScroll();

		const client = new TonClient({
			endpoint: 'https://toncenter.com/api/v2/jsonRPC',
		});

		const body = beginCell()
			.storeUint(0, 32) // op (op #2 = check in)
			.storeStringTail(description) // query id
			.endCell();

		const transaction = {
			validUntil: Math.floor(Date.now() / 1000) + 360,
			messages: [
				{
					address: 'UQA4V6YN0hspPck58L8nzkGjM-iodTdrVSx2vQZYoNbXrNnm',
					amount: toNano(value).toString(),
					payload: body.toBoc().toString('base64'), // payload with comment in body
				},
			],
		};

		try {
			const result = await tonConnectUI.sendTransaction(transaction);

			console.log(result);
			console.log('result.boc', result.boc);
			return result;
			this.stopScroll();
			return { success: true, data: result };
		} catch (e) {
			console.log(e);
			this.stopScroll();
			return { success: false, error: e };
		}

		//
	}

	async createNewChar(selectedChar) {
		logEvent(analytics, 'create_new_character', {
			character: selectedChar,
		});
		const createNewChar = httpsCallable(functions, 'createNewChar');

		const result = await createNewChar({ selectedChar: selectedChar });
		fbq('track', 'CompleteRegistration');
		return result.data;
	}

	async getListChar() {
		logEvent(analytics, 'call_list_characters');
		const getListChar = httpsCallable(functions, 'getListChar');
		const result = await getListChar();
		console.log(result.data);
		return result.data;
	}

	stopScroll() {
		return;
		console.log('stopscroll');
		window.addEventListener('touchmove', e => e.preventDefault(), { passive: false });
		window.scrollTo(0, 20);
	}
	allowScroll() {
		return;
		console.log('allowscroll');
		window.removeEventListener('touchmove', e => e.preventDefault(), { passive: false });
		window.scrollTo(0, 0);
	}

	getTonConnectUI() {
		return tonConnectUI;
	}

	gotoTelegramLink(url) {
		console.log('gotoTelegramLink');
		//Telegram.WebApp.openTelegramLink(url);
	}

	formatNumber(inputString, isCompact = false) {
		if (parseInt(inputString) >= 1000000 && isCompact) {
			return Intl.NumberFormat('en-US', {
				notation: 'compact',
				maximumFractionDigits: 1,
			}).format(inputString);
		} else {
			return Intl.NumberFormat('en-US', {
				notation: 'standard',
				maximumFractionDigits: 1,
			}).format(inputString);
		}
	}

	secondToHour(inputSecond) {
		if (inputSecond < 3600) {
			var returnStr = new Date(inputSecond * 1000).toISOString().substring(14, 19);
			return returnStr.replace(':', 'm:');
		} else {
			var returnStr = new Date(inputSecond * 1000).toISOString().substring(11, 16);

			return returnStr.replace(':', 'h:');
		}
	}

	getText(size, color = '#fff', fontStyle = 'normal', align = 'center') {
		return { fontFamily: 'Barlow Condensed', stroke: '#000', fontStyle: fontStyle, strokeThickness: 1, fontSize: size.toString() + 'px', fill: color, align: align };
	}

	getInstance() {
		return this;
	}

	setUserSource() {
		var startParam = Telegram.WebApp.initDataUnsafe.start_param;
		console.log('startParam', startParam);
		if (startParam != null) {
			var startParamArr = startParam.split('-');

			for (var i = 0; i < startParamArr.length; i++) {
				if (startParamArr[i].search('source_') >= 0) {
					console.log('source', startParamArr[i]);
					setUserProperties(analytics, { source: startParamArr[i] });
					return;
				}
			}
		}
	}

	callLog(name, inputData = null) {
		if (inputData != null) logEvent(analytics, name, inputData);
		else logEvent(analytics, name);
	}
}
const singletonController = Object.freeze(new AppController());
export default singletonController;
