import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import SimpleImageButton from '../components/SimpleImageButton';
import ItemStatRow from '../components/ItemStatRow';
import ProgressBar from '../components/ProgressBar';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import AppController from '../controller/AppController';

export default class MinePopup extends Phaser.Scene {
	constructor() {
		super('MinePopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'MinePopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const SAMPLE_DATA = [
			{ type: 'task', label: 'Max Stamina', icon: 'character_energy', current: '1500', next: '1800', level: 1, maxLevel: 5, price: 120, id: 'telegram_join', finished: false },

			{ type: 'task', label: 'Max Offline Reward', icon: 'offline_reward_icon', current: '1h', next: '1h30m', level: 1, maxLevel: 5, price: 120, id: 'telegram_join', finished: false },
			{ type: 'task', label: 'Power Up', icon: 'button_powerup_active', current: 'x60', next: 'x90', level: 1, maxLevel: 5, price: 120, id: 'telegram_join', finished: true },
		];

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 1000, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Mine', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('MinePopup');
		});

		this.scene.launch('LockScreen');
		const shopItemData = await AppController.getMineList();
		this.scene.stop('LockScreen');

		this.createPanel(shopItemData);
	}

	createPanel(shopItemData) {
		var taskList = [];
		for (var i = 0; i < shopItemData.data.mineData.length; i++) {
			var mineTask = shopItemData.data.mineData[i];
			var myLevel = shopItemData.data.myMineLevel[i];

			var currentValue = mineTask.level[myLevel].value;
			var nextValue = '';
			var price = '';
			if (myLevel < mineTask.level.length - 1) {
				nextValue = mineTask.level[myLevel + 1].value;
				price = mineTask.level[myLevel + 1].price;
			}

			if (i == 1) {
				console.log('nextValue', nextValue);

				currentValue = AppController.secondToHour(currentValue);

				if (nextValue != '') {
					nextValue = AppController.secondToHour(nextValue);
				}
			}

			if (i == 2) {
				currentValue = 'x' + currentValue;
				if (nextValue != '') {
					nextValue = 'x' + nextValue;
				}
			}

			var mineObj = { label: mineTask.name, icon: mineTask.icon, current: currentValue, next: nextValue, level: myLevel + 1, maxLevel: mineTask.level.length, price: price, id: mineTask.id };
			taskList.push(mineObj);
		}
		if (this.rowList) {
			for (var i = 0; i < this.rowList.length; i++) {
				this.rowList[i].destroy();
			}
		}

		this.rowList = [];

		for (var i = 0; i < taskList.length; i++) {
			var newRow = this.createItemRenderer(i + 1, taskList[i]);

			newRow.x = 360;
			newRow.y = 200 + i * 300;
			this.rowList.push(newRow);
		}
	}

	createItemRenderer(index, data) {
		var container = this.add.container();
		container.inputData = data;
		var name;

		var itemBg = this.add.nineslice(0, 0, 'main2', 'rank_row_bg', 600, 220, 13, 13, 9, 18).setOrigin(0.5, 0);

		var name = this.add.text(-120, 20, data.label, AppController.getText(30)).setOrigin(0.5, 0);
		var icon = this.add.image(name.x, name.y + 80, 'main2', data.icon).setOrigin(1, 0.5);

		var nextLevel = this.add.text(120, 20, 'Next Level', AppController.getText(30)).setOrigin(0.5, 0);
		var icon2 = this.add.image(nextLevel.x, nextLevel.y + 80, 'main2', data.icon).setOrigin(1, 0.5);

		var currentLabel = this.add.text(icon.x, icon.y, data.current, AppController.getText(40)).setOrigin(0, 0.5);
		var arrow = this.add.image(currentLabel.x + 120, currentLabel.y, 'main2', 'enhance_arrow').setOrigin(0, 0.5);
		var nextLabel = this.add.text(icon2.x, icon2.y, data.next, AppController.getText(40, '#00ff00', 'bold')).setOrigin(0, 0.5);

		if (data.icon == 'button_powerup_active') {
			icon.setScale(0.62, 0.62);
			icon2.setScale(0.62, 0.62);
		}

		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		container.removeInteractive();

		container.add(icon);
		container.add(icon2);
		container.add(name);
		container.add(nextLevel);
		container.add(currentLabel);
		container.add(nextLabel);
		container.add(arrow);

		var levelProgress = new ProgressBar(this, 0 - itemBg.width / 2 + 50, 170, 'exp', 0, itemBg.width - 100);
		var levelTxt = this.add.text(itemBg.x, levelProgress.y - 20, 'Level ' + data.level + '/' + data.maxLevel, AppController.getText(24)).setOrigin(0.5, 0.5);
		levelProgress.maxNumber = data.maxLevel;
		levelProgress.setProgress(data.level);
		container.add(levelProgress);
		container.add(levelTxt);

		if (data.level < data.maxLevel) {
			var buyButton = new PopupButton(this, 0, itemBg.height, 'button_green', data.price);
			container.add(buyButton);
			buyButton.on('pointerup', async function() {
				this.scene.scene.launch('LockScreen');
				const levelUpResult = await AppController.levelUpMine(data.id);
				this.scene.scene.stop('LockScreen');

				console.log('levelUpResult', levelUpResult);

				if (levelUpResult.data.success == false) {
					this.scene.scene.launch('Alert', { mess: levelUpResult.data.mess });
				} else {
					this.scene.scene.get('MainScene').pawSlot.updateNumber(levelUpResult.data.paw);
					//{ mineData: mineData, myMineLevel: myMineLevel, paw: myPaw };

					var myMaxStamina = levelUpResult.data.mineData[0].level[levelUpResult.data.myMineLevel[0]].value;
					var myPowerUpMultiplider = levelUpResult.data.mineData[2].level[levelUpResult.data.myMineLevel[2]].value;

					this.scene.scene.get('MainScene').staminaSlot.maxNumber = myMaxStamina;
					this.scene.scene.get('MainScene').staminaSlot.updateNumber(this.scene.scene.get('MainScene').staminaSlot.currentNumber);
					this.scene.scene.get('MainScene').powerUpButton.text = 'x' + myPowerUpMultiplider;
				}

				this.scene.createPanel(levelUpResult);
			});

			var earn_icon = this.add.image(buyButton.x - 40, buyButton.y, 'main2', 'paw_token_1').setOrigin(0.5, 0.5);
			earn_icon.setScale(0.5, 0.5);
			container.add(earn_icon);
		}

		return container;
	}
}
