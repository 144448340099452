import AppController from '../controller/AppController';
export default class NotificationDot extends Phaser.GameObjects.Container {
	constructor(scene, x, y) {
		super(scene, x, y);
		scene.add.existing(this);
		this.circleGraphic = scene.add.graphics();
		this.add(this.circleGraphic);
		this.circleGraphic.fillStyle(0xff161f, 1);
		this.circleGraphic.fillCircle(0, 0, 15);

		this.circleGraphic.lineStyle(2, 0xff161f);
		this.circleGraphic.strokeCircle(0, 0, 20);
		//this.txt = scene.add.text(0, 0, '20', AppController.getText(20)).setOrigin(0.5, 0.5);
		//this.add(this.txt);

		var aniGraphic = scene.add.graphics();
		this.add(aniGraphic);

		this.radius = 20;
		let tween = this.scene.tweens.add({
			targets: this,
			radius: 40,
			onUpdate: () => {
				aniGraphic.clear();
				aniGraphic.lineStyle(2, 0xff161f, (40 - this.radius) / 20);
				aniGraphic.strokeCircle(0, 0, this.radius);
			},
			duration: 1000,
			loop: -1,
			delay: 500,
		});
	}
}
