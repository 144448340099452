import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import * as humanizeDuration from 'humanize-duration';
import AppController from '../controller/AppController';

export default class OfflinePopup extends Phaser.Scene {
	constructor() {
		super('OfflinePopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'OfflinePopup' });
	}

	async init(data) {
		console.log(data);

		this.popupData = data;
	}

	preload() {}

	async create() {
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		r1.on('pointerup', function() {
			//this.scene.scene.stop('OfflinePopup');
		});

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 600, 800, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Offline Rewards', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var bigCoin = this.add.image(popupBG.x, popupBG.y - 30, 'main2', 'offline_earning_thumb');

		var leftTime = this.add.text(bigCoin.x, bigCoin.y - (bigCoin.height * 3) / 4, ['You have been left for:', humanizeDuration(this.popupData.offlineSecond * 1000)], LABEL_TEXT_CONFIG).setOrigin(0.5, 1);

		var offlineRewardData = Intl.NumberFormat('en-US', {
			notation: 'standard',
			maximumFractionDigits: 1,
		}).format(this.popupData.offlineReward);

		var total = this.add.text(bigCoin.x, bigCoin.y + bigCoin.height - 70, '+ ' + offlineRewardData, AppController.getText(70)).setOrigin(0.5, 0.5);

		var maximum = this.add.text(bigCoin.x, bigCoin.y + bigCoin.height - 10, 'Maximum offline time for reward is ' + AppController.secondToHour(this.popupData.maxOffline), AppController.getText(25)).setOrigin(0.5, 0.5);

		var sellButton = new PopupButton(this, popupBG.x, popupBG.y + popupBG.height / 2 - 70, 'button_blue', 'Collect');

		var x3Button = new PopupButton(this, popupBG.x + 120, popupBG.y + popupBG.height / 2 - 70, 'button_green', '    50');
		x3Button.visible = false;

		console.log('this.popupData.canx3', this.popupData.canx3);

		/*
		x3Button.disableBtn = !this.popupData.canx3;
		
		var coinIcon = this.add.image(x3Button.x - 20, x3Button.y, 'main2', 'paw_token_1').setOrigin(0.5, 0.5);
		coinIcon.setScale(0.5, 0.5);
		var offlineLabelBg = this.add.image(x3Button.x + 20, x3Button.y, 'main2', 'offline_x3_label').setOrigin(0, 1);
		var x3Label1 = this.add.text(offlineLabelBg.x + offlineLabelBg.width / 2, offlineLabelBg.y - 30, 'X3',AppController.getText(50)).setOrigin(0.5, 1);
		var x3Label2 = this.add.text(x3Label1.x, x3Label1.y + 15, 'collect',AppController.getText(24)).setOrigin(0.5, 1);
		*/
		sellButton.on('pointerup', async function() {
			sellButton.disableBtn = true;
			x3Button.disableBtn = true;
			r1.removeInteractive();
			const returnData = await AppController.getOfflineReward(false);
			console.log(returnData);
			this.scene.scene.stop('OfflinePopup');
			this.scene.scene.get('MainScene').createReward(returnData.reward, returnData.reward);

			this.scene.scene.get('MainScene').coinSlot.updateNumber(returnData.coin);
			this.scene.scene.get('MainScene').pawSlot.updateNumber(returnData.paw);
		});

		x3Button.on('pointerup', async function() {
			sellButton.disableBtn = true;
			x3Button.disableBtn = true;
			r1.removeInteractive();
			const returnData = await AppController.getOfflineReward(true);
			console.log(returnData);
			this.scene.scene.stop('OfflinePopup');
			this.scene.scene.get('MainScene').createReward(returnData.reward, returnData.reward);
			this.scene.scene.get('MainScene').coinSlot.updateNumber(returnData.coin);
			this.scene.scene.get('MainScene').pawSlot.updateNumber(returnData.paw);

			this.scene.scene.launch('Alert', { mess: 'You reiceved ' + AppController.formatNumber(returnData.reward) + ' ENGI !!' });
		});

		//
	}
}
