import AppController from '../controller/AppController';
export default class ProfitButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, text, earnValue, buttonWidth = 200, atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;

		scene.add.existing(this);
		this.txt = scene.add.text(0, -5, text, { fontFamily: 'Barlow Condensed', fontSize: '18px', fill: '#fff', align: 'center' }).setOrigin(0.5, 1);
		this.txtValue = scene.add.text(10, 12, '+' + earnValue, { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
		this.bg = scene.add.nineslice(0, 0, atlas, name + '_none', buttonWidth, 60, 3, 3, 3, 3);
		this.icon = scene.add.image(buttonWidth / 2 - 15, 0, atlas, 'earn_plus_normal').setOrigin(1, 0.5);
		this.coinIcon = scene.add
			.image(this.txtValue.width / 2 - 10, this.txtValue.y, atlas, 'token_energy')
			.setScale(0.4, 0.4)
			.setOrigin(0.5, 0.5);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);
		this.add(this.txtValue);
		this.add(this.coinIcon);
		this.add(this.icon);

		this.on('pointerover', function() {
			this.bg.setTexture('main2', name + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture('main2', name + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture('main2', name + '_none');
		});

		this.on('pointerup', function() {
			this.bg.setTexture('main2', name + '_active');
		});
	}

	setText(value) {
		this.txt.text = value;
	}

	setNumber(value) {
		this.txtValue.text = '+' + AppController.formatNumber(value);
		this.coinIcon.x = -this.txtValue.width / 2 - 5;
	}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main2', this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture('main2', this.btnName + '_none');
		}
	}
}
