import Phaser from 'phaser';
import IconButton from '../components/IconButton';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class ReferralPopup extends Phaser.Scene {
	constructor() {
		super('ReferralPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'ReferralPopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 700, 40, 40, 40, 50);
		popupBG.setInteractive();

		var row_bg = this.add.nineslice(360, 635, 'main2', 'popup_row_bg', popupBG.width - 60, popupBG.height - 60, 31, 5, 7, 32);

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Friends', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('ReferralPopup');
		});

		var refBoxLeft = this.add.nineslice(220, 600, 'main2', 'ref_box', 250, 300, 10, 47, 9, 52);
		var refBoxRight = this.add.nineslice(refBoxLeft.x + refBoxLeft.width + 25, refBoxLeft.y, 'main2', 'ref_box', refBoxLeft.width, refBoxLeft.height, 10, 47, 9, 52);

		var refBoxBottom = this.add.nineslice(popupBG.x, popupBG.y + 200, 'main2', 'ref_box', refBoxLeft.width * 2 + 25, 150, 10, 47, 9, 52);

		var refBoxLeftIcon = this.add.image(refBoxLeft.x, refBoxLeft.y - refBoxLeft.height / 2, 'main2', 'ref_token_earn');
		var refBoxRightIcon = this.add.image(refBoxRight.x, refBoxRight.y - refBoxRight.height / 2, 'main2', 'ref_friends');

		var reftBoxLeftTitle = this.add.text(refBoxLeft.x, refBoxLeftIcon.y + refBoxLeftIcon.height / 2 + 10, 'Your reward', LABEL_TEXT_CONFIG).setOrigin(0.5, 0);
		var reftBoxRightTitle = this.add.text(refBoxRight.x, refBoxRightIcon.y + refBoxRightIcon.height / 2 + 10, 'Your friends', LABEL_TEXT_CONFIG).setOrigin(0.5, 0);

		this.totalTokensRewards = this.add.text(refBoxLeft.x, reftBoxLeftTitle.y + 70, '0', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0);
		this.totalFriends = this.add.text(refBoxRight.x, reftBoxRightTitle.y + 70, '0', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0);

		const refInfo = await AppController.getMyReferral();
		//console.log('refinfo', refInfo);

		this.totalFriends.text = refInfo.data.totalFriends;

		this.totalTokensRewards.text = refInfo.data.totalRefCoin;
		this.linkButton = new IconButton(this, refBoxBottom.x, refBoxBottom.y, 'bt_big_green', 'GET YOUR INVITATION LINK', 'bt_copy', refBoxBottom.width - 100);
		this.linkButton.on('pointerup', async function() {
			const refLink = 'https://t.me/DogMutantBot/gameapp?startapp=dog_' + AppController.getUserId();
			try {
				navigator.clipboard.writeText(refLink);
			} catch (e) {}

			this.isLoading = true;
			this.setText('Sending...');
			this.disableBtn = true;
			const returnData = await AppController.getMyReferralLink();

			/*gotoTelegramLink('https://t.me/dogMutantBot');
			if (returnData.refLink != null) {
				//await navigator.clipboard.writeText(returnData.refLink);
			}*/
			this.disableBtn = false;
			AppController.callLog('get_ref_link');

			this.setText('Copied to clipboard!');
		});
	}
}
