import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';
export default class CurrentEquipInfoPopup extends Phaser.Scene {
	constructor() {
		super('CurrentEquipInfoPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'CurrentEquipInfoPopup' });
	}

	async init(data) {
		console.log(data);

		this.itemData = data.item;
		this.enhanceList = data.enhanceList;

		if (data.item.position == 'head') {
			this.slotName = 'Head Slot';
		}
		if (data.item.position == 'body') {
			this.slotName = 'Body Slot';
		}
		if (data.item.position == 'leg') {
			this.slotName = 'Leg Slot';
		}
		if (data.item.position == 'hand_left') {
			this.slotName = 'Left Hand Slot';
		}
		if (data.item.position == 'hand_right') {
			this.slotName = 'Right Hand Slot';
		}
	}

	preload() {}

	create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: 'white', align: 'left' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 550, 500, 40, 40, 40, 50);
		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, this.slotName, AppController.getText(40)).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('CurrentEquipInfoPopup');
		});

		var color = ['gray', 'green', 'blue', 'purple', 'yellow'];
		var rarity = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];

		var updown = [0, 0, 0];

		var enhanceLevel = 0;

		if (this.itemData.enhancedLevel != null && this.itemData.enhancedLevel > 0) {
			enhanceLevel = this.itemData.enhancedLevel;
		}

		var row1 = new ItemStatRow(this, popupBG.x, popupBG.y, '[' + rarity[this.itemData.rarity - 1] + '] ' + this.itemData.name, this.itemData.position + '_' + this.itemData.image, color[this.itemData.rarity - 1], { lv: this.itemData.level, atk: this.itemData.atk, def: this.itemData.def, hp: this.itemData.hp }, null, enhanceLevel);

		if (enhanceLevel < this.enhanceList.length) {
			var enhanceBtn = new PopupButton(this, popupBG.x, row1.y + 190, 'button_green', 'Enhance ' + this.slotName, 250);
			enhanceBtn.on('pointerup', function() {
				this.scene.scene.stop('CurrentEquipInfoPopup');
				this.scene.scene.launch('EnhancedEquipPopup', { item: this.scene.itemData, enhanceList: this.scene.enhanceList });
			});
		}
	}
}
