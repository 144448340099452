import Phaser from 'phaser';
import IconButton from '../components/IconButton';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class ReferralPopup extends Phaser.Scene {
	constructor() {
		super('FriendView');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'FriendView' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x141420).setOrigin();

		var closeButton = new SimpleImageButton(this, 80, 50, 'bt_back');
		var panelTitle = this.add.text(r1.x, closeButton.y, 'Friends Zone', AppController.getText(50)).setOrigin(0.5, 0.5);
		var earnedTxt = this.add.text(r1.x, panelTitle.y + 60, 'Earned: ', AppController.getText(30)).setOrigin(1, 0.5);
		var earn_icon = this.add
			.image(r1.x, earnedTxt.y, 'main2', 'paw_token_1')
			.setOrigin(0, 0.5)
			.setScale(0.5, 0.5);

		closeButton.on('pointerup', function() {
			this.scene.scene.stop('FriendView');
		});

		var container = this.add.container();

		this.scene.launch('LockScreen');
		const friendListInfo = await AppController.getMyFriendsList();
		this.scene.stop('LockScreen');
		var earnedValue = this.add.text(earn_icon.x + earn_icon.width / 2 + 5, earnedTxt.y, friendListInfo.data.earned, AppController.getText(30, '#fff200')).setOrigin(0, 0.5);

		console.log(friendListInfo);

		var rewardArray = [
			{ title: 'Invite friends', value: '+' + friendListInfo.data.refRewards[0], description: 'for you and friend' },
			{ title: 'Friend with Telegram Premium', value: '+' + friendListInfo.data.refRewards[1], description: 'for you and friend' },
			{ title: 'Commission bonus', value: '+' + friendListInfo.data.refRewards[2] + '%', description: 'purchase $PAW by friend' },
		];

		const friendArray = friendListInfo.data.friendArray;

		/*var friendArray = [
			{ name: 'Dinh Anh Quan', purchased: 1000, reward: 43, rank: 'Gold' },
			{ name: 'Dinh Quang Huy', purchased: 1000, reward: 413, rank: 'Diamond' },
			{ name: 'Nguyen Khanh Duong', purchased: 1000, reward: 3, rank: 'Diamond' },
		];*/

		var title1 = this.add.text(0, 0, 'Invite friends, earn bonus ', AppController.getText(40)).setOrigin(0, 0.5);
		container.add(title1);

		var itemBg = this.add.nineslice(r1.x - 25, 40, 'main2', 'rank_row_bg', 680, rewardArray.length * 150, 13, 13, 9, 18).setOrigin(0.5, 0);
		container.add(itemBg);

		for (var i = 0; i < rewardArray.length; i++) {
			var titleInviteFriends = this.add.text(40, 80 + i * 140, rewardArray[i].title, AppController.getText(30)).setOrigin(0, 0.5);
			container.add(titleInviteFriends);
			var earn_icon2 = this.add
				.image(titleInviteFriends.x, titleInviteFriends.y + 50, 'main2', 'paw_token_1')
				.setOrigin(0, 0.5)
				.setScale(0.7, 0.7);
			container.add(earn_icon2);
			var valueInviteFriend = this.add.text(earn_icon2.x + 50, earn_icon2.y, rewardArray[i].value, AppController.getText(40, '#fff200')).setOrigin(0, 0.5);
			container.add(valueInviteFriend);
			var valueInviteFriendTxt = this.add.text(valueInviteFriend.x + valueInviteFriend.width + 10, earn_icon2.y, rewardArray[i].description, AppController.getText(30)).setOrigin(0, 0.5);
			container.add(valueInviteFriendTxt);
			if (i < rewardArray.length - 1) {
				var line = this.add.rectangle(itemBg.x, valueInviteFriendTxt.y + 50, 600, 2, 0x31486e).setOrigin(0.5, 0);
				container.add(line);
			}
		}

		var title2 = this.add.text(title1.x, title1.y + itemBg.height + 80, 'Friend list (' + friendArray.length + ')', AppController.getText(40)).setOrigin(0, 0.5);
		container.add(title2);

		var itemBg2 = this.add.nineslice(r1.x - 25, title2.y + 40, 'main2', 'rank_row_bg', 680, friendArray.length * 120, 13, 13, 9, 18).setOrigin(0.5, 0);
		container.add(itemBg2);

		for (var i = 0; i < friendArray.length; i++) {
			var friendNameTxt = this.add.text(40, itemBg2.y + 40 + i * 100, friendArray[i].name, AppController.getText(30)).setOrigin(0, 0.5);
			container.add(friendNameTxt);

			var descriptionTxt = this.add.text(40, friendNameTxt.y + 40, '' + ' - Purchased: ' + friendArray[i].totalPurchase + ' $PAW', AppController.getText(22)).setOrigin(0, 0.5);
			container.add(descriptionTxt);

			var earn_icon3 = this.add
				.image(600, descriptionTxt.y - 20, 'main2', 'paw_token_1')
				.setOrigin(0, 0.5)
				.setScale(0.7, 0.7);
			container.add(earn_icon3);
			var rewardFriend = this.add.text(earn_icon3.x - 10, earn_icon3.y, '+' + friendArray[i].totalCommission, AppController.getText(40, '#fff200')).setOrigin(1, 0.5);
			container.add(rewardFriend);

			if (i < friendArray.length - 1) {
				var line = this.add.rectangle(itemBg2.x, descriptionTxt.y + 30, 600, 2, 0x31486e).setOrigin(0.5, 0);
				container.add(line);
			}
		}

		var config = {
			x: r1.x,
			y: r1.y,
			width: r1.width - 50,
			height: r1.height - 370,
			scrollMode: 0,
			slider: false,
			panel: {
				child: container,
				mask: {
					padding: 20,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		this.linkButton = new IconButton(this, r1.x, r1.y + r1.height / 2 - 120, 'bt_big_green', 'GET YOUR INVITATION LINK', 'bt_copy', 350);
		this.linkButton.on('pointerup', async function() {
			const refLink = 'https://t.me/DogMutantBot/gameapp?startapp=dog_' + AppController.getUserId();
			try {
				navigator.clipboard.writeText(refLink);
			} catch (e) {}

			this.isLoading = true;
			this.setText('Sending...');
			this.disableBtn = true;
			const returnData = await AppController.getMyReferralLink();

			/*gotoTelegramLink('https://t.me/dogMutantBot');
			if (returnData.refLink != null) {
				//await navigator.clipboard.writeText(returnData.refLink);
			}*/
			this.disableBtn = false;
			AppController.callLog('get_ref_link');

			this.setText('Copied to clipboard!');
		});

		return;

		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 700, 40, 40, 40, 50);
		popupBG.setInteractive();

		var row_bg = this.add.nineslice(360, 635, 'main2', 'popup_row_bg', popupBG.width - 60, popupBG.height - 60, 31, 5, 7, 32);

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Friends', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var refBoxLeft = this.add.nineslice(220, 600, 'main2', 'ref_box', 250, 300, 10, 47, 9, 52);
		var refBoxRight = this.add.nineslice(refBoxLeft.x + refBoxLeft.width + 25, refBoxLeft.y, 'main2', 'ref_box', refBoxLeft.width, refBoxLeft.height, 10, 47, 9, 52);

		var refBoxBottom = this.add.nineslice(popupBG.x, popupBG.y + 200, 'main2', 'ref_box', refBoxLeft.width * 2 + 25, 150, 10, 47, 9, 52);

		var refBoxLeftIcon = this.add.image(refBoxLeft.x, refBoxLeft.y - refBoxLeft.height / 2, 'main2', 'ref_token_earn');
		var refBoxRightIcon = this.add.image(refBoxRight.x, refBoxRight.y - refBoxRight.height / 2, 'main2', 'ref_friends');

		var reftBoxLeftTitle = this.add.text(refBoxLeft.x, refBoxLeftIcon.y + refBoxLeftIcon.height / 2 + 10, 'Your reward', LABEL_TEXT_CONFIG).setOrigin(0.5, 0);
		var reftBoxRightTitle = this.add.text(refBoxRight.x, refBoxRightIcon.y + refBoxRightIcon.height / 2 + 10, 'Your friends', LABEL_TEXT_CONFIG).setOrigin(0.5, 0);

		this.totalTokensRewards = this.add.text(refBoxLeft.x, reftBoxLeftTitle.y + 70, '0', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0);
		this.totalFriends = this.add.text(refBoxRight.x, reftBoxRightTitle.y + 70, '0', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0);

		this.linkButton = new IconButton(this, refBoxBottom.x, refBoxBottom.y, 'bt_big_green', 'GET YOUR INVITATION LINK', 'bt_copy', refBoxBottom.width - 100);

		const refInfo = await AppController.getMyReferral();
		//console.log('refinfo', refInfo);

		this.totalFriends.text = refInfo.data.totalFriends;

		this.totalTokensRewards.text = refInfo.data.totalRefCoin;

		this.linkButton.on('pointerup', async function() {
			const refLink = 'https://t.me/DogMutantBot/gameapp?startapp=dog_' + AppController.getUserId();
			try {
				navigator.clipboard.writeText(refLink);
			} catch (e) {}

			this.isLoading = true;
			this.setText('Sending...');
			this.disableBtn = true;
			const returnData = await AppController.getMyReferralLink();

			/*gotoTelegramLink('https://t.me/dogMutantBot');
			if (returnData.refLink != null) {
				//await navigator.clipboard.writeText(returnData.refLink);
			}*/
			this.disableBtn = false;
			AppController.callLog('get_ref_link');

			this.setText('Copied to clipboard!');
		});
	}
}
