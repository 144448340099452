import AppController from '../controller/AppController';
import SimpleImageButton from './SimpleImageButton';
import ImageButton from './Button';
export default class BossPanel extends Phaser.GameObjects.Container {
	constructor(scene, x, y, atlas = 'main2') {
		super(scene, x, y);
		scene.add.existing(this);

		var closeButton = new SimpleImageButton(scene, 80, 0, 'bt_back');
		this.add(closeButton);

		var infoButton = new SimpleImageButton(scene, 650, 0, 'shimo_boss_info');
		this.add(infoButton);

		closeButton.on('pointerup', event => {
			scene.scene.get('MainScene').switchBossBattle(false);
		});

		var leftDecor = scene.add.image(140, 0, atlas, 'shimo_boss_decor').setOrigin(0, 0.5);
		this.add(leftDecor);
		var rightDecor = scene.add.image(500, 0, atlas, 'shimo_boss_decor').setOrigin(1, 0.5);
		rightDecor.setScale(-1, 1);
		this.add(rightDecor);

		var myRankTxt = scene.add.text(360, 0, 'Your Rank: 123', AppController.getText(40)).setOrigin(0.5, 0.5);
		this.add(myRankTxt);

		var leaderboardBtn = new ImageButton(scene, 360, myRankTxt.y + 80, 'menu_arena', 'Leaderboard', 'main2', '_normal');
		this.add(leaderboardBtn);

		leaderboardBtn.on('pointerup', event => {
			scene.scene.get('MainScene').loadBossLeaderboardView();
		});

		var infoPanel = scene.add.nineslice(360, leaderboardBtn.y + 200, 'main2', 'rank_row_grey_bg', 600, 250, 13, 13, 9, 18);

		this.add(infoPanel);

		this.infoTxt = scene.add.rexBBCodeText(90, infoPanel.y - infoPanel.height / 2 + 20, '', { fontFamily: 'Barlow Condensed', fontSize: '22px', align: 'left' }).setOrigin(0, 0);
		this.add(this.infoTxt);
		this.infoTxt.text = AppController.getBossAnnoucement();
	}

	updateAnnoucement(inputAnnoucement) {
		var newArr = [];
		Object.keys(inputAnnoucement).forEach(e => {
			newArr.push(inputAnnoucement[e]);
		});
		this.infoTxt.text = newArr;
	}
}
