import Phaser from 'phaser';
import TabButton from '../components/TabButton';
import ItemStatRow from '../components/ItemStatRow';
import SimpleImageButton from '../components/SimpleImageButton';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import AppController from '../controller/AppController';

export default class BossLeaderboardPopup extends Phaser.Scene {
	constructor() {
		super('BossLeaderboardPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'BossLeaderboardPopup' });
	}

	async init(data) {
		console.log(data);

		this.myRank = data.myRank;
		this.rankList = data.rankList;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		this.popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 1000, 40, 40, 40, 50);
		this.popupBG.setInteractive();

		var titleBg = this.add.nineslice(this.popupBG.x, this.popupBG.y - this.popupBG.height / 2, 'main2', 'popup_title_bg', this.popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Leaderboard', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('BossLeaderboardPopup');
		});

		/*this.leftButton = new TabButton(this, this.popupBG.x - 100, 220, 'Rank by date');
		this.rightButton = new TabButton(this, this.popupBG.x + 100, 220, 'Rank by week');

		this.leftButton.on('pointerup', function() {
			if (!this.selected) {
				this.scene.resetItem();
				this.selectedButton(true);
				this.scene.rightButton.selectedButton(false);
				//this.scene.createEloLeaderboard();
			}
		});

		this.rightButton.on('pointerup', function() {
			if (!this.selected) {
				this.scene.resetItem();
				this.selectedButton(true);
				this.scene.leftButton.selectedButton(false);
				//this.scene.createRankLeaderboard(this.scene.myRank);
			}
		});
		this.leftButton.selectedButton(true);*/
		this.createEloLeaderboard();

		//this.createRankLeaderboard(this.myRank);
		//this.rightButton.selectedButton(true);

		//
	}

	resetItem() {
		if (this.childPanel) {
			this.childPanel.destroy();
		}
		if (this.panel) {
			this.panel.destroy();
		}
		if (this.myBg) {
			this.myBg.destroy();
		}
		if (this.myName) {
			this.myName.destroy();
		}

		if (this.myElo) {
			this.myElo.destroy();
		}
		if (this.myIndex) {
			this.myIndex.destroy();
		}
		if (this.rankShield) {
			this.rankShield.destroy();
		}
		if (this.rightArrow) {
			this.rightArrow.destroy();
		}
		if (this.leftArrow) {
			this.leftArrow.destroy();
		}
		if (this.rankText) {
			this.rankText.destroy();
		}

		if (this.fromText) {
			this.fromText.destroy();
		}
	}

	async createRankLeaderboard(inputRank) {
		this.resetItem();
		var hasLeft = true;
		var hasRight = true;
		var fromNumber = 0;
		var nextRank = '';
		var preRank = '';

		for (var i = 0; i < this.rankList.length; i++) {
			if (this.rankList[i].name == inputRank) {
				if (i == 0) {
					hasLeft = false;
				} else {
					preRank = this.rankList[i - 1].name;
				}
				if (i == this.rankList.length - 1) {
					hasRight = false;
				} else {
					nextRank = this.rankList[i + 1].name;
				}

				fromNumber = this.rankList[i].coin;
				break;
			}
		}

		this.rankShield = this.add.image(this.popupBG.x, 360, 'rank_' + inputRank);
		this.rankText = this.add.text(this.popupBG.x, this.rankShield.y + this.rankShield.height / 3.5, inputRank, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
		this.fromText = this.add.text(this.rankText.x, this.rankText.y + 40, 'From: ' + AppController.formatNumber(fromNumber, true), { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff200', align: 'center' }).setOrigin(0.5, 0.5);

		if (hasRight) {
			this.rightArrow = this.add.image(this.rankShield.x + 200, this.rankShield.y, 'main2', 'icon_go').setOrigin(0, 0.5);
			this.rightArrow.setInteractive();
			this.rightArrow.on('pointerup', function() {
				this.scene.createRankLeaderboard(nextRank);
			});
		}

		if (hasLeft) {
			this.leftArrow = this.add
				.image(this.rankShield.x - 200, this.rankShield.y, 'main2', 'icon_go')
				.setOrigin(0, 0.5)
				.setScale(-1, 1);
			this.leftArrow.setInteractive();
			this.leftArrow.on('pointerup', function() {
				this.scene.createRankLeaderboard(preRank);
			});
		}

		this.childPanel = this.rexUI.add.sizer({
			x: 0,
			y: 0,
			orientation: 'y',
			space: { item: 0, top: 10, bottom: 10 },
		});

		this.scene.launch('LockScreen');
		const leaderboardData = await AppController.getRankLeaderboard(inputRank);
		this.scene.stop('LockScreen');

		for (var i = 0; i < leaderboardData.data.length; i++) {
			this.childPanel.add(this.createRankItemRenderer(i + 1, leaderboardData.data[i]));
		}

		//var abc = this.createItemRenderer();

		var config = {
			x: this.popupBG.x,
			y: leaderboardData.hasMe ? this.popupBG.y + 140 : this.popupBG.y + 180,
			width: this.popupBG.width,
			height: leaderboardData.hasMe ? this.popupBG.height - 540 : this.popupBG.height - 500,
			scrollMode: 0,
			slider: false,
			panel: {
				child: this.childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		this.panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);
		if (leaderboardData.hasMe) {
			this.myBg = this.add.nineslice(this.popupBG.x, this.popupBG.y + this.popupBG.height / 2 - 80, 'main2', 'rank_row_player_bg', 600, 120, 13, 13, 9, 18);
			this.myName = this.add.text(this.myBg.x - 150, this.myBg.y - 38, leaderboardData.myName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
			this.myElo = this.add.text(this.myBg.x - 150, this.myBg.y - 5, AppController.formatNumber(leaderboardData.myCoin), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);
			this.myIndex = this.add.text(this.myBg.x - 250, this.myBg.y, leaderboardData.myPosition, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
		}
	}

	async createEloLeaderboard() {
		this.resetItem();
		this.childPanel = this.rexUI.add.sizer({
			x: 0,
			y: 0,
			orientation: 'y',
			space: { item: 0, top: 10, bottom: 10 },
		});

		this.scene.launch('LockScreen');
		const leaderboardData = await AppController.getBossLeaderboard();
		this.scene.stop('LockScreen');

		for (var i = 0; i < leaderboardData.data.length; i++) {
			this.childPanel.add(this.createItemRenderer(i + 1, leaderboardData.data[i]));
		}

		//var abc = this.createItemRenderer();

		var config = {
			x: this.popupBG.x,
			y: this.popupBG.y,
			width: this.popupBG.width,
			height: this.popupBG.height - 220,
			scrollMode: 0,
			slider: false,
			panel: {
				child: this.childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		this.panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		this.myBg = this.add.nineslice(this.popupBG.x, this.popupBG.y + this.popupBG.height / 2 - 80, 'main2', 'rank_row_player_bg', 600, 120, 13, 13, 9, 18);
		this.myName = this.add.text(this.myBg.x - 150, this.myBg.y - 38, leaderboardData.myName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		this.myElo = this.add.text(this.myBg.x - 150, this.myBg.y - 5, 'Elo: ' + leaderboardData.myPoint.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);
		this.myIndex = this.add.text(this.myBg.x - 250, this.myBg.y, leaderboardData.myPosition, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
	}

	createItemRenderer(index, data, frame = 'rank_row_bg') {
		var container = this.add.container();

		var itemBg = this.add.nineslice(0, 0, 'main2', 'rank_row_bg', 600, 110, 13, 13, 9, 18);
		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		//container.setInteractive();
		var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		if (index < 4) {
			var medal = this.add.image(indexTxt.x, indexTxt.y, 'main2', 'medal_' + index);
			container.add(medal);
		}

		var name = this.add.text(-150, -38, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		var elo = this.add.text(-150, -5, 'Boss Point: ' + data.bossPoint.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);

		container.add(name);
		container.add(elo);
		//
		container.add(indexTxt);

		return container;
	}

	createRankItemRenderer(index, data, frame = 'rank_row_bg') {
		var container = this.add.container();

		var itemBg = this.add.nineslice(0, 0, 'main2', 'rank_row_bg', 600, 110, 13, 13, 9, 18);
		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		//container.setInteractive();
		var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		if (index < 4) {
			var medal = this.add.image(indexTxt.x, indexTxt.y, 'main2', 'medal_' + index);
			container.add(medal);
		}

		var name = this.add.text(-150, -38, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);

		var coinImg = this.add
			.image(-150, 15, 'main2', 'token_energy')
			.setScale(0.5, 0.5)
			.setOrigin(0, 0.5);
		var elo = this.add.text(coinImg.x + coinImg.width / 2 + 5, coinImg.y, AppController.formatNumber(data.coin), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0.5);

		container.add(name);
		container.add(elo);

		//
		container.add(indexTxt);
		container.add(coinImg);

		return container;
	}
}
