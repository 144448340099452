import AppController from '../controller/AppController';
export default class EquipSlot extends Phaser.GameObjects.Container {
	constructor(scene, x, y, position, atlas = 'main2') {
		super(scene, x, y);
		scene.add.existing(this);
		this.position = position;

		this.txt = scene.add.text(0, 0, '', AppController.getText(24)).setOrigin(0.5, 0);
		this.bg = scene.add.image(0, 0, atlas, 'equip_none_bg');
		this.enhanceTxt = scene.add.text(this.bg.x + this.bg.width / 2 - 10, this.bg.y - this.bg.width / 2, '', AppController.getText(24)).setOrigin(0.5, 0);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);

		this.on('pointerover', function() {});

		this.on('pointerdown', function() {});

		this.on('pointerout', function() {});

		this.on('pointerup', function() {});

		this.txt.y = this.height / 2 - 20;
	}

	setItem(itemData) {
		this.itemData = itemData;
		if (this.itemThumb) {
			this.itemThumb.setTexture('main2', this.position + '_' + itemData.image);
		} else {
			this.itemThumb = this.scene.add.image(0, 0, 'main2', this.position + '_' + itemData.image);
			this.add(this.itemThumb);
			this.add(this.txt);
			this.add(this.enhanceTxt);
		}
		var color = ['gray', 'green', 'blue', 'purple', 'yellow'];
		this.bg.setTexture('main2', 'equip_rarity_' + color[this.itemData.rarity - 1]);
		this.txt.text = 'Lv.' + this.itemData.level;

		var enhanceLevel = 0;

		if (this.itemData.enhancedLevel != null && this.itemData.enhancedLevel > 0) {
			enhanceLevel = this.itemData.enhancedLevel;
		}

		this.enhanceTxt.text = '+' + enhanceLevel;
	}

	removeItem() {}

	setRarely(color) {}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main2', this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture('main2', this.btnName + '_none');
		}
	}
}
