import Phaser from 'phaser';
import ImageButton from '../components/Button';
import SimpleImageButton from '../components/SimpleImageButton';
import IconButton from '../components/IconButton';
import EquipSlot from '../components/EquipSlot';
import SkillButton from '../components/SkillButton';
import SkillButtonBig from '../components/SkillButtonBig';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';
import ProgressBar from '../components/ProgressBar';
import CurrencySlot from '../components/CurrencySlot';
import SlotButton from '../components/SlotButton';
import NotificationDot from '../components/NotificationDot';
import SettingPopup from './SettingPopup';
import PopupButton from '../components/PopupButton';
import ProfitButton from '../components/ProfitButton';
import TutorialHand from '../components/TutorialHand';
import BossPanel from '../components/BossPanel';

import { DogMutant } from '../contracts/tact_DogMutant';

import AppController from '../controller/AppController';

export default class MainScene extends Phaser.Scene {
	constructor() {
		super('MainScene');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'MainScene' });
	}

	preload() {
		this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');
	}

	createNumber(targetObj, content, color = '#fff') {
		if (this.battleInfo != null && this.currentTurn >= this.battleInfo.length) {
			return;
		}

		//console.log('current turn' + this.currentTurn);
		//console.log('battleInfo' + this.battleInfo.length);

		var dognumber = this.floatingNumbers.createFloatingText({
			textOptions: {
				fontFamily: 'Barlow Condensed',
				fontSize: 42,
				color: color,
				strokeThickness: 3,
				fontWeight: 'bold',
				stroke: '#000000',
				shadow: {
					offsetX: 0,
					offsetY: 0,
					color: '#000',
					blur: 4,
					stroke: true,
					fill: false,
				},
			},
			text: content,
			align: 'top-center',
			parentObject: targetObj,
			animation: 'up',
			timeToLive: 500,
			animationEase: 'Sine.easeOut',
		});
	}

	battleCallback() {
		if (this.onResultScreen == true) {
			this.onResultScreen = false;
			this.scene.stop('ResultPopup');

			this.resetBattleField();

			this.loadPlayerData();

			return;
		}

		if (this.justDead == true) {
			this.onResultScreen = true;

			if (this.isBoss) {
				this.loadResultPopup(this.isWin, this.pawReward, this.eloIncrease);
			} else {
				this.loadResultPopup(this.isWin, this.resultCoin, this.eloIncrease);
			}

			this.justDead = false;
			this.time.delayedCall(2500, this.battleCallback, null, this);
			return;
		}

		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;
		var skillData = null;
		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			skillBtn = this.leftSkill;
			skillData = this.rightSkillData;
		} else {
			attackChar = this.leftPlayer;
			attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			skillBtn = this.rightSkill;
			skillData = this.leftSkillData;
		}

		var skillTime = 3900;

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();

				if (this.justDead != true && this.onResultScreen != true) attackChar.animationState.setAnimation(0, 'Idle', true);
				this.isUpdatingCoin = false;
			},
			event: (entry, event) => {
				if (event.data.name == 'Hit') {
					if (this.battleInfo[this.currentTurn].isSkill && skillData.impact != null) {
						skillTime = 2900;
						var impact = this.add.spine(hitChar.x, hitChar.y, skillData.impact.toString() + '-data', skillData.impact.toString() + '-atlas');

						if (skillData.impact_position == 'top') {
							impact.y -= 170;
						}

						impact.animationState.addListener({
							complete: entry => {
								this.scene.remove(impact);
								impact.destroy();
							},
						});

						impact.setOrigin(0.5, 0.5);
						impact.scaleX = 0.5;
						impact.scaleY = 0.5;
						impact.animationState.setAnimation(0, 'Skill', false);
					}
					if (this.battleInfo[this.currentTurn].isSkill) {
						const numberToCreate = Math.floor(this.battleInfo[this.currentTurn].damage / skillData.hit);

						this.createNumber(hitChar, '-' + numberToCreate, '#ff4d54');
					} else {
						this.createNumber(hitChar, '-' + this.battleInfo[this.currentTurn].damage, '#ff4d54');
					}
					this.resultCoin = 0;
					this.isWin = true;
					if (this.battleInfo[this.currentTurn].hasOwnProperty('coinDrop')) {
						if (this.battleInfo[this.currentTurn].isSkill && hitChar == this.rightPlayer) {
							this.createReward(Math.floor(this.battleInfo[this.currentTurn].coinDrop / skillData.hit), this.battleInfo[this.currentTurn].coinDrop);
						} else if (this.battleInfo[this.currentTurn].isSkill) {
							this.createReward(Math.floor(this.battleInfo[this.currentTurn].coinDrop / skillData.hit), this.battleInfo[this.currentTurn].coinDrop);
						} else {
							this.createReward(this.battleInfo[this.currentTurn].coinDrop, this.battleInfo[this.currentTurn].coinDrop);
						}
						this.resultCoin = this.battleInfo[this.currentTurn].coinDrop;
					}

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					if (skillBtn != null) skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'Die', false);

						if (hitChar == this.leftPlayer) {
							this.isWin = false;
						}
						this.justDead = true;
					} else {
						hitChar.animationState.setAnimation(0, 'Hit', false);
						hitChar.animationState.addAnimation(0, 'Idle', true);
					}
				}
			},
		});

		var turnTime = 1000;

		console.log('this.battleInfo', this.battleInfo);
		console.log('this.currentTurn', this.currentTurn);

		if (this.currentTurn >= this.battleInfo.length) {
			return;
		}

		if (this.battleInfo[this.currentTurn].isSkill) {
			attackChar.animationState.setAnimation(0, skillData.animation, false);
			turnTime = skillTime;
			if (attackSkill != null) attackSkill.resetSkill();
		} else {
			attackChar.animationState.setAnimation(0, 'NormalAttack', false);
		}

		if (this.justDead == true) {
			turnTime = 2000;
		}

		this.time.delayedCall(turnTime, this.battleCallback, null, this);
	}

	timerEvent() {
		if (this.justDead == true) {
			return;
		}

		if (this.onResultScreen == true) {
			return;
		}

		/*if (this.onResultScreen == true) {
			this.onResultScreen = false;
			this.scene.stop('ResultPopup');
		}

		if (this.justDead == true) {
			this.onResultScreen = true;
			this.loadResultPopup(this.isWin, this.resultCoin);
			this.justDead = false;
			return;
		}*/

		if (this.onBattle == false) {
			if (this.isBoss == true) {
				return;
			}

			if (this.leftPlayer) {
				this.leftPlayer.animationState.setAnimation(0, 'NormalAttack', false);

				this.leftPlayer.animationState.addListener({
					complete: entry => {
						this.leftPlayer.animationState.clearListeners();

						this.leftPlayer.animationState.setAnimation(0, 'Idle', true);
						this.isUpdatingCoin = false;
					},
					event: (entry, event) => {
						if (event.data.name == 'Hit' && !(this.isBoss == true)) {
							//if (['dogzilla', 'mechzilla'].includes(this.myCharacterData.character.id)) this.sound.play('hit_tail');
							//if (['dog_kong', 'dognom', 'dog_shiba', 'skar_king', 'irondog'].includes(this.myCharacterData.character.id)) this.sound.play('hit_punch');
							//if (['dogfly'].includes(this.myCharacterData.character.id)) this.sound.play('hit_shot');

							this.rightPlayer.animationState.setAnimation(0, 'hit', false);

							this.rightPlayer.animationState.addAnimation(0, 'idle', true);

							var myreward = (Math.round(this.myCharacterData.power * 0.008) + this.myCharacterData.coin_per_second) * this.myCharacterData.time_between_turn;

							if (this.powerUp != null && this.powerUp > 0) {
								myreward = myreward * this.rewardMultiplier;
								this.powerUp -= 1;
								if (this.powerUp == 0) {
									this.powerUpCharacter(false);
								}
							}

							this.createReward(myreward, myreward);

							this.createNumber(this.rightPlayer, '+' + myreward, '#fff200');
						}
					},
				});
			}

			return;
		}

		return;

		if (this.battleInfo == null || this.battleInfo.length == 0 || this.currentTurn >= this.battleInfo.length - 1) {
			this.resetBattleField();

			this.loadPlayerData();

			if (!this.isLoading) {
				//	this.loadNewOpponent();
			}

			return;
		}
		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;
		var skillData = null;

		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			skillBtn = this.leftSkill;
			skillData = this.rightSkillData;
		} else {
			attackChar = this.leftPlayer;
			attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			skillBtn = this.rightSkill;
			skillData = this.leftSkillData;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();
				attackChar.animationState.setAnimation(0, 'Idle', true);
				this.isUpdatingCoin = false;
			},
			event: (entry, event) => {
				if (event.data.name == 'Hit') {
					if (this.battleInfo[this.currentTurn].isSkill && skillData.impact != null) {
						var impact = this.add.spine(hitChar.x, hitChar.y, skillData.impact.toString() + '-data', skillData.impact.toString() + '-atlas');

						if (skillData.impact_position == 'top') {
							impact.y -= 170;
						}

						impact.animationState.addListener({
							complete: entry => {
								this.scene.remove(impact);
								impact.destroy();
							},
						});

						impact.setOrigin(0.5, 0.5);
						impact.scaleX = 0.5;
						impact.scaleY = 0.5;
						impact.animationState.setAnimation(0, 'Skill', false);
					}

					if (this.battleInfo[this.currentTurn].isSkill) {
						const numberToCreate = Math.floor(this.battleInfo[this.currentTurn].damage / skillData.hit);

						this.createNumber(hitChar, '-' + numberToCreate, '#ff4d54');
					} else {
						this.createNumber(hitChar, '-' + this.battleInfo[this.currentTurn].damage, '#ff4d54');
					}
					this.resultCoin = 0;
					this.isWin = true;
					if (this.battleInfo[this.currentTurn].hasOwnProperty('coinDrop')) {
						if (this.battleInfo[this.currentTurn].isSkill) {
							this.createReward(Math.floor(this.battleInfo[this.currentTurn].coinDrop / skillData.hit), this.battleInfo[this.currentTurn].coinDrop);
						} else {
							this.createReward(this.battleInfo[this.currentTurn].coinDrop, this.battleInfo[this.currentTurn].coinDrop);
						}
						this.resultCoin = this.battleInfo[this.currentTurn].coinDrop;
					}

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'Die', false);

						if (hitChar == this.leftPlayer) {
							this.isWin = false;
						}
						this.justDead = true;
					} else {
						hitChar.animationState.setAnimation(0, 'Hit', false);

						hitChar.animationState.addAnimation(0, 'Idle', true);
					}
				}
			},
		});

		if (this.battleInfo[this.currentTurn].isSkill) {
			attackChar.animationState.setAnimation(0, skillData.animation, false);

			attackSkill.resetSkill();
		} else {
			attackChar.animationState.setAnimation(0, 'NormalAttack', false);
		}
	}

	async create() {
		console.log('maintainInfo', AppController.getMaintain());

		this.bgMusic = this.sound.add('main');
		this.bgMusic.loop = true;
		this.bgMusic.mute = true;

		this.battleMusic1 = this.sound.add('battle_01');
		this.battleMusic2 = this.sound.add('battle_02');

		//this.scene.launch('Alert', { mess: ' aha!', label: 'adad', url: 'https://google.com' });

		const bgNumber = 1 + Math.floor(Math.random() * 5);
		this.gamebg = this.add.image(0, 0, 'bg-' + bgNumber);
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		this.bottomBar = this.add.image(0, 0, 'main2', 'bottom_menu_bg');

		this.bottomBar.scaleX = this.gamebg.width / this.bottomBar.width;
		this.bottomBar.x = (this.bottomBar.width * this.bottomBar.scaleX) / 2;
		this.bottomBar.y = this.gamebg.height - this.bottomBar.height;

		this.inventorybg = this.add.image(0, 0, 'main2', 'player_equipments_bg');
		this.inventorybg.x = this.gamebg.x;
		this.inventorybg.y = this.bottomBar.y - this.bottomBar.height / 2 - this.inventorybg.height / 2;

		this.staminaSlot = new CurrencySlot(this, 120, 50, 'character_energy', 180);

		var connectButtonLabel = 'Connect Wallet';
		if (AppController.getTonConnectUI().walletInfo != null) {
			const friendlyFormat = AppController.currentFriendlyAddress;

			connectButtonLabel = friendlyFormat.substr(0, 4) + '....' + friendlyFormat.substr(-4, 4);
		}

		this.connectButton = new SlotButton(this, 280, this.staminaSlot.y, 'ton_wallet', connectButtonLabel);
		//this.connectButton.visible = false;

		const unsubscribeModal = AppController.getTonConnectUI().onModalStateChange(state => {
			this.scene.stop('LockScreen');
		});

		const unsubscribe = AppController.getTonConnectUI().onStatusChange(walletAndwalletInfo => {
			this.scene.stop('LockScreen');
			var connectButtonLabel = 'Connect Wallet';
			if (walletAndwalletInfo == null) {
			} else {
				const friendlyFormat = AppController.currentFriendlyAddress; //toUserFriendlyAddress(AppController.getTonConnectUI().account.address);

				connectButtonLabel = friendlyFormat.substr(0, 4) + '....' + friendlyFormat.substr(-4, 4);
			}
			this.connectButton.setText(connectButtonLabel);
		});

		this.coinSlot = new CurrencySlot(this, this.gamebg.x, 120, 'token_energy', 200);
		this.coinSlot.visible = false;

		this.profitButtonLeft = new ProfitButton(this, 120, this.coinSlot.y, 'earn_button', 'Profit per hour', '');
		this.profitButtonRight = new ProfitButton(this, this.gamebg.width - 120, this.coinSlot.y, 'earn_button', 'Earn per win', '');

		this.shiba = this.add.spine(360, 1020, 'mutantdog-data', 'mutantdog-atlas');
		this.shiba.animationState.setAnimation(0, 'Idle_01', true);
		this.shiba.scaleX = 0.7;
		this.shiba.scaleY = 0.7;

		this.mutantmachine = this.add.image(0, 0, 'main2', 'mutant_machine');
		this.mutantmachine.x = this.gamebg.x;
		this.mutantmachine.y = this.bottomBar.y - 100;

		this.mutantPriceBg = this.add.nineslice(this.mutantmachine.x, this.mutantmachine.y + 110, 'main2', 'mutant_currency_bg', 160, 0, 35, 16, 0, 0);
		this.mutantPriceText = this.add.text(this.mutantPriceBg.x + 10, this.mutantPriceBg.y, '', AppController.getText(25)).setOrigin(0.5, 0.5);

		this.mutantLevelBg = this.add.image(this.mutantmachine.x, this.mutantmachine.y - 10, 'main2', 'radius_currency_bg');
		this.mutantLevelText = this.add.text(this.mutantLevelBg.x, this.mutantLevelBg.y, '', AppController.getText(24)).setOrigin(0.5, 0.5);

		this.mutantExpProgress = new ProgressBar(this, 0, this.bottomBar.y + 40, 'hp_green', 0, this.gamebg.width);
		this.rankProgress = new ProgressBar(this, 0, this.coinSlot.y + 40, 'exp', 0, this.gamebg.width);
		this.rankTxt = this.add.text(this.rankProgress.x, this.rankProgress.y + 20, '', AppController.getText(20)).setOrigin(0, 0);

		//this.button0 = new SimpleImageButton(this, this.gamebg.x, this.bottomBar.y, 'mutant_text');

		this.countDownText = this.add.text(this.gamebg.x, 400, '', AppController.getText(36)).setOrigin(0.5, 0.5);

		this.button0 = new IconButton(this, this.gamebg.x, this.bottomBar.y - 50, 'bt_big_blue', '', 'mutant_text', 180);
		this.effectMutantButton = this.add.spine(this.button0.x, this.button0.y, 'button-data', 'button-atlas');
		this.effectMutantButton.animationState.setAnimation(0, 'animation', true);
		this.effectMutantButton.setScale(0.52, 0.5);
		this.effectMutantButton.visible = false;

		this.button1 = new ImageButton(this, 50, this.bottomBar.y - 30, 'menu_shop', 'Shop', 'main2', '_none');
		this.button2 = new ImageButton(this, 135, this.bottomBar.y - 30, 'menu_mine', 'Mine', 'main2', '_normal');
		this.button3 = new ImageButton(this, 220, this.bottomBar.y - 30, 'menu_earn', 'Earn', 'main2', '_normal');

		this.button4 = new ImageButton(this, this.gamebg.width - this.button3.x, this.bottomBar.y - 30, 'menu_arena', 'Leader.b', 'main2', '_normal');
		this.button5 = new ImageButton(this, this.gamebg.width - this.button2.x, this.bottomBar.y - 30, 'menu_friends', 'Friends', 'main2', '_normal');
		this.button6 = new ImageButton(this, this.gamebg.width - this.button1.x, this.bottomBar.y - 30, 'menu_airdrop', 'Airdrop', 'main2', '_normal');

		this.headEquip = new EquipSlot(this, 170, 800, 'head');
		this.headEquip.on('pointerup', event => {
			this.loadEquipmentInfoPopup(this.myCharacterData.equipments.head, 'head');
		});
		this.bodyEquip = new EquipSlot(this, this.headEquip.x, this.headEquip.y + 100, 'body');
		this.bodyEquip.on('pointerup', event => {
			this.loadEquipmentInfoPopup(this.myCharacterData.equipments.body, 'body');
		});
		this.footEquip = new EquipSlot(this, this.headEquip.x, this.bodyEquip.y + 100, 'leg');
		this.footEquip.on('pointerup', event => {
			this.loadEquipmentInfoPopup(this.myCharacterData.equipments.leg, 'leg');
		});
		this.leftHandEquip = new EquipSlot(this, this.headEquip.x + 100, this.bodyEquip.y, 'hand_left');
		this.leftHandEquip.on('pointerup', event => {
			this.loadEquipmentInfoPopup(this.myCharacterData.equipments.hand_left, 'hand_left');
		});
		this.rightHandEquip = new EquipSlot(this, this.headEquip.x - 100, this.bodyEquip.y, 'hand_right');
		this.rightHandEquip.on('pointerup', event => {
			this.loadEquipmentInfoPopup(this.myCharacterData.equipments.hand_right, 'hand_right');
		});

		this.settingBtn = new SimpleImageButton(this, this.inventorybg.x + this.inventorybg.width / 2 - 50, this.inventorybg.y - this.inventorybg.height / 2 + 50, 'setting');
		this.settingBtn.on('pointerup', event => {
			this.openSettingPopup();
		});

		this.button0.on('pointerup', event => {
			this.loadNewItem();
		});

		this.button1.on('pointerup', event => {
			this.loadShopView();
			this.button1.hideNotification();
		});

		this.button2.on('pointerup', event => {
			this.loadMineView();
			this.button2.hideNotification();
		});

		this.button5.on('pointerup', event => {
			this.loadReferralView();
		});

		this.button4.on('pointerup', event => {
			this.loadLeaderboardView();
		});

		this.button3.on('pointerup', event => {
			this.loadEarnView();
		});

		this.connectButton.on('pointerup', event => {
			this.scene.launch('LockScreen');
			AppController.loadTonConnect();
		});

		this.button6.on('pointerup', event => {
			this.loadAirdropView();
		});

		this.battleInfo = [];
		this.currentTurn = -1;

		//this.loadLeaderboardView();
		//var newPopupScene = this.scene.launch('OfflinePopup', { offlineSecond: 123111135, offlineReward: 30 });
		//return;

		this.onCreate = true;
		this.powerUp = 0;

		window.addEventListener('backOnline', event => {
			if (!this.onCreate) {
				this.resetBattleField();
				this.loadPlayerData();
			}
		});

		this.twitterInfo = {};

		window.addEventListener('twitterData', event => {
			if (event.detail != null) {
				if (this.twitterInfo == null) {
					this.scene.launch('Alert', { mess: 'Twitter connected to ' + event.detail.username });
				}
			}

			this.twitterInfo = event.detail;
		});

		window.addEventListener('pawEvent', event => {
			if (event.detail != null) {
				if (this.pawSlot != null) {
					this.pawSlot.updateNumber(event.detail);
				} else {
				}
			}
		});

		window.addEventListener('alert', event => {
			if (event.detail != null && event.detail != false) {
				this.resetBattleField();
				this.loadPlayerData();
				this.scene.launch('Alert', { mess: event.detail, isBackend: true });
			}
		});

		window.addEventListener('maintain', event => {
			console.log('maintaindetail', event.detail);

			if (this.bossPanel) {
				this.bossPanel.updateAnnoucement(event.detail.boss_annoucement);
			}

			if (event.detail.maintain == true) {
				this.scene.stop('LockScreen');
				this.scene.start('MaintainScene');
			}
		});

		window.addEventListener('goOffline', event => {
			this.resetBattleField();
		});

		this.loadPlayerData();

		this.time.addEvent({
			callback: function() {
				if (this.staminaSlot.visible == true && this.startButton != null) {
					if (this.staminaSlot.maxNumber != null && this.staminaSlot.currentNumber < this.staminaSlot.maxNumber) {
						this.staminaSlot.updateNumber(this.staminaSlot.currentNumber + 1);

						if (!this.onBattle && !this.isLoading && !this.isWaitNewBattle && this.startButton != null && this.startButton.scene != null) {
							if (this.powerUp == 0 && this.staminaSlot.currentNumber >= this.myCharacterData.stamina_per_battle * 5) {
								try {
									this.powerUpButton.disableBtn = false;
								} catch (e) {
									console.log(e); // Logs the error
								}
							} else {
								try {
									this.powerUpButton.disableBtn = true;
								} catch (e) {
									console.log(e); // Logs the error
								}
							}

							if (this.powerUp == 0 && this.staminaSlot.currentNumber >= this.myCharacterData.stamina_per_battle) {
								try {
									this.startButton.disableBtn = false;
								} catch (e) {
									console.log(e); // Logs the error
								}
							} else {
								try {
									this.startButton.disableBtn = true;
								} catch (e) {
									console.log(e); // Logs the error
								}
							}
						}
					}
				}
			},
			callbackScope: this,
			delay: 1000, // 1000 = 1 second
			loop: true,
		});

		//this.loadShopView();

		//this.loadEarnView();

		return;
	}

	async loadCharsPopup() {
		const listChars = await AppController.getListChar();
		this.scene.start('ChooseCharacterScene', listChars);
	}

	resetBattleField() {
		const bgNumber = 1 + Math.floor(Math.random() * 5);
		this.gamebg = this.gamebg.setTexture('bg-' + bgNumber);

		if (this.startButton) {
			this.startButton.destroy();
		}

		if (this.bossBtn) {
			this.bossBtn.destroy();
		}

		if (this.bossbg) {
			this.bossbg.destroy();
		}

		if (this.powerUpButton) {
			this.powerUpButton.destroy();
		}

		if (this.pawSlot) {
			this.pawSlot.destroy();
		}

		if (this.autoButton) {
			this.autoButton.destroy();
		}

		if (this.leftPlayer) {
			this.leftPlayer.destroy();
		}

		if (this.rightPlayer) {
			this.rightPlayer.destroy();
		}

		if (this.myName) {
			this.myName.destroy();
		}

		if (this.leftPower) {
			this.leftPower.destroy();
		}

		if (this.rightPower) {
			this.rightPower.destroy();
		}

		if (this.oppName) {
			this.oppName.destroy();
		}

		if (this.leftSkill) {
			this.leftSkill.destroy();
		}

		if (this.rightSkill) {
			this.rightSkill.destroy();
		}

		if (this.leftHP) {
			this.leftHP.destroy();
		}

		if (this.rightHP) {
			this.rightHP.destroy();
		}
		this.battleInfo = null;
		this.currentTurn = -1;
		this.button0.disableBtn = false;
	}

	async loadReferralView() {
		//var newPopupScene = this.scene.launch('ReferralPopup');
		var newPopupScene = this.scene.launch('FriendView');
	}

	async loadLeaderboardView() {
		var newPopupScene = this.scene.launch('LeaderboardPopup', this.myCharacterData);
	}

	async loadBossLeaderboardView() {
		var newPopupScene = this.scene.launch('BossLeaderboardPopup', this.myCharacterData);
	}

	async loadAirdropView() {
		var newPopupScene = this.scene.launch('AirdropPopup', this.myCharacterData);
	}

	async loadEarnView() {
		var newPopupScene = this.scene.launch('EarnPopup');
	}

	async loadMineView() {
		var newPopupScene = this.scene.launch('MinePopup');
	}

	async loadShopView() {
		var newPopupScene = this.scene.launch('ShopPopup');
	}

	async loadCharacterInfoPopup() {
		var newPopupScene = this.scene.launch('CharacterInfoPopup', this.myCharacterData);
	}

	async openSettingPopup() {
		var newPopupScene = this.scene.launch('SettingPopup', this.myCharacterData);
	}

	async loadResultPopup(isWin = true, earn = 1000, eloIncrease = 0) {
		var newPopupScene = this.scene.launch('ResultPopup', { isWin: isWin, earn: earn, eloIncrease: eloIncrease, isBoss: this.isBoss });
	}

	async loadEquipmentInfoPopup(item, position) {
		if (item == null) {
			return;
		}
		item.position = position;

		var newPopupScene = this.scene.launch('CurrentEquipInfoPopup', { item: item, enhanceList: this.myCharacterData.enhanceList });
	}

	async powerUpCharacter(active = true) {
		console.log('here 1', this.powerUp);

		if (active == false) {
			console.log('here 2', this.powerUp);
			if (this.leftPlayerAura) {
				this.leftPlayerAura.destroy();
			}
			if (this.leftPlayerLightning) {
				this.leftPlayerLightning.destroy();
			}
			this.powerUp = 0;

			this.startButton.disableBtn = false;
			this.startButton.visible = true;

			if (this.tutorialHand != null && this.myCharacterData.numBattle == 0) {
				this.tutorialHand.visible = true;
				this.tutorialHand.x = this.startButton.x;
				this.tutorialHand.y = this.startButton.y;
			}

			if (this.staminaSlot.currentNumber >= this.myCharacterData.stamina_per_battle * 5) {
				try {
					this.powerUpButton.disableBtn = false;
				} catch (e) {
					console.log(e); // Logs the error
				}
			} else {
				try {
					this.powerUpButton.disableBtn = true;
				} catch (e) {
					console.log(e); // Logs the error
				}
			}

			return;
		}

		console.log('here 3', this.powerUp);

		if (this.powerUpButton.disableBtn == true) {
			return;
		}

		if (this.powerUp != 0) {
			return;
		}
		this.powerUp = -1;
		this.powerUpButton.disableBtn = true;
		this.startButton.disableBtn = true;

		if (this.tutorialHand) {
			this.tutorialHand.visible = false;
		}

		console.log('here 4', this.powerUp);

		var returnValue = await AppController.powerUpCharacter();
		console.log('powerupReturn', returnValue);
		if (returnValue.data.success == false || returnValue.data.transaction.success == false) {
			this.powerUpButton.disableBtn = false;
			this.startButton.disableBtn = false;
			this.powerUp = 0;
			return;
		}

		this.leftPlayerAura = this.add.spine(this.leftPlayer.x, this.leftPlayer.y, 'aura-data', 'aura-atlas');
		this.leftPlayerAura.animationState.setAnimation(0, 'animation', true);
		this.leftPlayerAura.setScale(0.6, 0.6);

		this.leftPlayerLightning = this.add.spine(this.leftPlayer.x, this.leftPlayer.y, 'lightning-data', 'lightning-atlas');
		this.leftPlayerLightning.animationState.setAnimation(0, 'animation', true);
		this.leftPlayerLightning.setScale(0.6, 0.6);

		this.powerUp = returnValue.data.powerUpMultiplier;
		this.rewardMultiplier = returnValue.data.powerUpRewardMultiplier;
		this.staminaSlot.updateNumber(returnValue.data.currentStamina);
	}

	async loadNewItem() {
		if (!this.isLoading) {
			this.isLoading = true;
		} else {
			return;
		}

		this.shiba.animationState.setAnimation(0, 'Action_01', true);
		this.button0.disableBtn = true;
		this.shibaLoading = true;

		if (this.myCharacterData.mutant_machine.level == 1 && this.myCharacterData.mutant_machine.exp == 0) {
			AppController.callLog('first_mutant');
		} else {
		}

		const itemResult = await AppController.summonNewItem();

		if (itemResult.data == false || itemResult.data.success == false) {
			this.button0.disableBtn = false;
			this.checkMutantButtonDisable();
			this.shiba.animationState.setAnimation(0, 'Idle_01', true);
			this.shibaLoading = false;
			this.isLoading = false;
			return;
		}

		var currentCoinNumber = parseInt(this.coinSlot.currentNumber);

		var itemPrice = parseInt(itemResult.data.price);

		this.coinSlot.updateNumber(currentCoinNumber - itemResult.data.price);
		this.updateRankData(currentCoinNumber - itemResult.data.price);
		this.mutantPrice = itemResult.data.machine.cost;

		this.mutantPriceText.text = AppController.formatNumber(this.mutantPrice);

		if (itemResult.data.airdrop != null && itemResult.data.airdrop.length > 0) {
			var remainingFree = itemResult.data.airdrop.length;

			for (var i = 0; i < itemResult.data.airdrop.length; i++) {
				if (itemResult.data.airdrop[i] != 0) {
					break;
				}
				remainingFree -= 1;
			}
			this.mutantPriceText.text = 'Free (' + remainingFree + '/' + itemResult.data.airdrop.length + ')';
		}

		this.mutantLevelText.text = 'Lv.' + itemResult.data.machine.level.toString();

		this.mutantExpProgress.maxNumber = (itemResult.data.machine.level + 1) * 4;
		this.mutantExpProgress.setProgress(itemResult.data.machine.exp);

		if (itemResult.data.machine.level < 2) {
			this.startButton.visible = false;
			this.powerUpButton.visible = false;
		} else {
			if (this.staminaSlot.currentNumber < this.staminaSlot.maxNumber) {
				//this.startButton.visible = true;
			} else {
				//this.startButton.visible = false;
			}

			this.powerUpButton.visible = true;

			this.effectMutantButton.visible = false;
		}
		this.myCharacterData.mutant_machine.level = itemResult.data.machine.level;
		this.myCharacterData.mutant_machine.exp = itemResult.data.machine.exp;

		if (this.tutorialHand) {
			this.tutorialHand.visible = false;
		}

		const oldItem = this.myEquipments[itemResult.data.position];
		var updateObj = { oldItem: oldItem, newItem: itemResult.data };

		this.shiba.animationState.setAnimation(0, 'Action_02', false);
		this.shiba.animationState.addListener({
			complete: entry => {
				this.shibaLoading = false;
				this.shiba.animationState.clearListeners();
				this.button0.setInteractive();
				this.button0.disableBtn = false;
				this.checkMutantButtonDisable();
			},
			event: (entry, event) => {
				if (event.data.name == 'big') {
					var newPopupScene = this.scene.launch('NewEquipPopup', updateObj);
					console.log(newPopupScene);
					this.isLoading = false;
				}
			},
		});

		this.shiba.animationState.addAnimation(0, 'Idle_01', true);
	}

	checkMutantButtonDisable() {
		if (this.onBattle) {
			this.button0.disableBtn = true;
			return;
		}

		if (this.shibaLoading != null && this.shibaLoading == true) {
			return;
		}

		if (this.coinSlot.currentNumber < this.mutantPrice) {
			this.button0.disableBtn = true;
		} else {
			this.button0.disableBtn = false;
		}
	}

	switchBossBattle(isBoss = true) {
		this.isBoss = isBoss;

		if (isBoss == true) {
			this.bossbg.visible = true;
			this.staminaPerBattleBg.visible = false;
			//this.staminaPerBattleTxt.visible = false;

			if (this.myCharacterData.bossFreeFight > 0) {
				this.staminaPerBattleTxt.text = 'Free: ' + this.myCharacterData.bossFreeFight;
			} else {
				this.staminaPerBattleTxt.text = 'Cost: 50 PAW';
			}

			this.startButton.setText('FIGHT');

			if (this.rightPlayer) {
				this.rightPlayer.destroy();
			}
			this.rightPlayer = this.add.spine(650, 550, 'shimo-data', 'shimo-atlas');
			this.rightPlayer.setOrigin(0.5, 0.5);
			this.rightPlayer.scaleX = 0.5;
			this.rightPlayer.scaleY = 0.5;
			this.rightPlayer.animationState.setAnimation(0, 'Idle', true);
			this.rightPlayer.animationState.timeScale = this.gameSpeed;

			if (this.leftPlayer) {
				this.leftPlayer.x = 150;
				this.myName.x = this.leftPlayer.x;
				this.leftPower.x = this.myName.x;
			}
			if (this.bossPanel) {
				this.bossPanel.destroy();
			}

			this.bossPanel = new BossPanel(this, 0, 800);

			//this.loadBossLeaderboardView();
		} else {
			if (this.bossPanel) {
				this.bossbg.visible = false;
				this.staminaPerBattleBg.visible = true;
				this.staminaPerBattleTxt.visible = true;
				this.staminaPerBattleTxt.text = this.myCharacterData.stamina_per_battle;
				this.startButton.setText('Battle');

				if (this.rightPlayer != null) {
					this.rightPlayer.destroy();
				}
				this.rightPlayer = this.add.spine(550, 440, 'punchingbag-data', 'punchingbag-atlas');
				this.rightPlayer.setOrigin(0.5, 0.3);
				this.rightPlayer.scaleX = 0.5;
				this.rightPlayer.scaleY = 0.5;

				if (this.leftPlayer) {
					this.leftPlayer.x = this.gamebg.x;
					this.myName.x = this.leftPlayer.x;
					this.leftPower.x = this.myName.x;
				}
				this.bossPanel.destroy();
			}
		}
	}

	updateEquipments() {
		if (this.myEquipments == null) return;

		this.myCharacterData.equipments = this.myEquipments;

		var totalEquip = 0;

		if (this.myEquipments.head) {
			this.headEquip.setItem(this.myEquipments.head);
			totalEquip += 1;
		}
		if (this.myEquipments.body) {
			this.bodyEquip.setItem(this.myEquipments.body);
			totalEquip += 1;
		}
		if (this.myEquipments.leg) {
			this.footEquip.setItem(this.myEquipments.leg);
			totalEquip += 1;
		}
		if (this.myEquipments.hand_left) {
			this.leftHandEquip.setItem(this.myEquipments.hand_left);
			totalEquip += 1;
		}
		if (this.myEquipments.hand_right) {
			this.rightHandEquip.setItem(this.myEquipments.hand_right);
			totalEquip += 1;
		}

		if (this.myCharacterData.numBattle == 0) {
			if (this.powerUpButton.visible == true) {
				if (this.tutorialHand != null) {
					this.tutorialHand.visible = true;
					this.tutorialHand.x = this.powerUpButton.x;
					this.tutorialHand.y = this.powerUpButton.y;
				}
			} else {
				if (this.tutorialHand != null) {
					this.tutorialHand.visible = true;
					this.tutorialHand.x = this.button0.x;
					this.tutorialHand.y = this.button0.y;
				}
			}
		} else {
			if (this.tutorialHand != null) {
				this.tutorialHand.visible = false;
			}
		}
	}

	async loadTonConnect() {
		if (tonConnectUI.walletInfo == null) {
			tonConnectUI.openModal();
			return;
		}

		const client = new TonClient({
			endpoint: 'https://toncenter.com/api/v2/jsonRPC',
		});

		/*const contract = new DogMutant(
			Address.parse('EQDMJVS5Fpxy1eWVDpG3R312Tkp00hIoldDX4OOSQWMG7C00') // replace with your address from tutorial 2 step 8
		);*/

		const contract = new RoosterBattle(Address.parse('EQCnbuLU1Xo72u9ihKAw88BzrfyPA2BI5J3E8nNTWC_zxXAX'));

		var openedContract = client.open(contract);

		///var result = await openedContract.sendCheckin(sender, 0.2);

		var address = openedContract?.address.toString();
		console.log(address);

		const body = beginCell()
			.storeUint(2, 32) // op (op #2 = check in)
			.storeUint(0, 64) // query id
			.endCell();

		const transaction = {
			validUntil: Math.floor(Date.now() / 1000) + 360,
			messages: [
				{
					address: address,
					amount: toNano(0.05).toString(),
					payload: body.toBoc().toString('base64'), // payload with comment in body
				},
			],
		};

		try {
			const result = await tonConnectUI.sendTransaction(transaction);

			// you can use signed boc to find the transaction
			//const someTxData = await myAppExplorerService.getTransaction(result.boc);
			//alert('Transaction was sent successfully', someTxData);
			console.log(result);
			console.log('result.boc', result.boc);
		} catch (e) {
			console.log(e);
		}

		//
	}

	async loadPlayerData() {
		this.onBattle = false;
		//this.isLoading = true;

		this.scene.launch('LockScreen');
		const characterData = await AppController.getMyCharacter();

		if (AppController.getMaintain().maintain == true) {
			return;
		}
		AppController.setUserSource();
		if (characterData.success == false) {
			await this.loadCharsPopup();

			this.scene.stop('LockScreen');
			return;
		} else {
			this.scene.stop('LockScreen');

			if (this.bgMusic) {
				//this.bgMusic.play();
			}
			this.battleMusic1.stop();
			this.battleMusic2.stop();

			this.myCharacterData = characterData.data;

			var lastPlay = characterData.data.lastPlay;

			if (localStorage.getItem('shopAndMine') == null || localStorage.getItem('shopAndMine') < 2) {
				if (localStorage.getItem('shopAndMine') == null) {
					localStorage.setItem('shopAndMine', 0);
				} else {
					localStorage.setItem('shopAndMine', localStorage.getItem('shopAndMine') + 1);
				}

				this.button1.showNotification();
				this.button2.showNotification();
			}

			this.myMineLevel = [0, 0, 0];
			if (this.myCharacterData.mineLevel != null) {
				this.myMineLevel = this.myCharacterData.mineLevel;
			}

			//this.loadShopView();

			AppController.enhanceLevelList = this.myCharacterData.enhanceList;

			const powerUpMultiplier = this.myCharacterData.minelist[2].level[this.myMineLevel[2]].value;
			this.powerUpButton = new ImageButton(this, 100, 420, 'button_powerup', 'x' + powerUpMultiplier, 'main2', '_none');

			this.powerUpButton.on('pointerup', event => {
				this.powerUpCharacter();
			});

			this.bossBtn = new SimpleImageButton(this, 650, 250, 'ido_button');
			this.bossBtn.visible = false;
			this.bossBtn.on('pointerup', event => {
				//this.loadNewOpponent(true);
				//this.switchBossBattle();
				//this.scene.launch('Alert', { mess: 'Boss Battle has not started yet.' });
				//Telegram.WebApp.openLink('https://dogmutant.com/blog/domu-ido', { try_instant_view: true });
				Telegram.WebApp.openTelegramLink('https://t.me/DogMutantNews/47');
			});

			if (this.bossbg != null) {
				this.bossbg.destroy();
			}
			this.bossbg = this.add.image(0, 0, 'bg-1');
			this.bossbg.x = this.bossbg.width / 2;
			this.bossbg.y = this.bossbg.height / 2;
			this.bossbg.visible = false;

			this.startButton = new PopupButton(this, this.gamebg.x, 630, 'button_red', 'BATTLE', 200);
			this.startButton.disableBtn = true;
			this.staminaPerBattleTxt = this.add.text(10, -50, '', { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
			this.staminaPerBattleBg = this.add.nineslice(0, this.staminaPerBattleTxt.y, 'main2', name + 'energy_currency_bg', 100, 0, 35, 15, 0, 0).setOrigin(0.5, 0.5);

			//this.autoButton = new PopupButton(this, 450, this.startButton.y, 'button_orange', 'AUTO', 150);

			this.startButton.add(this.staminaPerBattleBg);
			this.startButton.add(this.staminaPerBattleTxt);

			this.startButton.on('pointerup', event => {
				this.loadNewOpponent(this.isBoss);
			});

			this.staminaPerBattleTxt.text = this.myCharacterData.stamina_per_battle;

			this.startButton.disableBtn = false;

			if (this.myCharacterData.stamina_per_battle <= characterData.data.stamina) {
				this.startButton.disableBtn = false;
			}

			if (this.myCharacterData.numBattle != null && this.myCharacterData.numBattle > 0) {
				this.button1.visible = true;
				this.button2.visible = true;
				this.button3.visible = true;
				this.button4.visible = true;
				this.button5.visible = true;
				this.button6.visible = true;
			} else {
				this.tutorialHand = new TutorialHand(this, this.button0.x, this.button0.y);
				this.button1.visible = false;
				this.button2.visible = false;
				this.button3.visible = false;
				this.button4.visible = false;
				this.button5.visible = false;
				this.button6.visible = false;

				if (characterData.data.stamina < characterData.data.maxStamina) {
					this.startButton.visible = true;
				} else {
					this.startButton.visible = false;
				}
			}

			if (this.myCharacterData.mutant_machine.level < 2) {
				this.startButton.visible = false;
				this.powerUpButton.visible = false;
				this.glowMutantButton();
			}

			const staminaPerBattleTxtPowerup = this.add.text(10, -70, '', { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
			const staminaPerBattleBgPowerup = this.add.nineslice(0, staminaPerBattleTxtPowerup.y, 'main2', name + 'energy_currency_bg', 100, 0, 35, 15, 0, 0).setOrigin(0.5, 0.5);
			this.powerUpButton.add(staminaPerBattleBgPowerup);
			this.powerUpButton.add(staminaPerBattleTxtPowerup);

			staminaPerBattleTxtPowerup.text = this.myCharacterData.stamina_per_battle * 5;

			if (this.myCharacterData.stamina_per_battle * 5 > characterData.data.stamina) {
				this.powerUpButton.disableBtn = true;
			}

			this.profitButtonLeft.setNumber((Math.round(this.myCharacterData.power * 0.008) + this.myCharacterData.coin_per_second) * 3600);

			this.profitButtonRight.setNumber(this.myCharacterData.power + this.myCharacterData.elo * 3);

			this.mutantPrice = this.myCharacterData.mutant_machine.cost;

			this.rankList = this.myCharacterData.rankList;

			this.mutantPriceText.text = this.mutantPrice;

			if (this.myCharacterData.airdrop != null && this.myCharacterData.airdrop.length > 0) {
				var remainingFree = this.myCharacterData.airdrop.length;
				for (var i = 0; i < this.myCharacterData.airdrop.length; i++) {
					if (this.myCharacterData.airdrop[i] != 0) {
						break;
					}
					remainingFree -= 1;
				}
				this.mutantPriceText.text = 'Free (' + remainingFree + '/' + this.myCharacterData.airdrop.length + ')';
			}

			this.mutantLevelText.text = 'Lv.' + this.myCharacterData.mutant_machine.level.toString();
			this.mutantExpProgress.maxNumber = (this.myCharacterData.mutant_machine.level + 1) * 4;
			this.mutantExpProgress.setProgress(this.myCharacterData.mutant_machine.exp);

			this.gameSpeed = 1;
			if (characterData.data.hasOwnProperty('speed')) {
				this.gameSpeed = characterData.data.speed;
				if (this.gameSpeed > 4) {
					this.gameSpeed = 4;
				}
			}

			if (this.leftPlayer != null) {
				this.leftPlayer.destroy();
			}
			this.leftPlayer = this.add.spine(this.gamebg.x, 540, characterData.data.skin + '-data', characterData.data.skin + '-atlas');
			//this.leftPlayer.setOrigin(0.5, 0.5);
			this.leftPlayer.skinName = characterData.data.skin;
			this.leftPlayer.scaleX = 0.5;
			this.leftPlayer.scaleY = 0.5;

			this.leftPlayer.animationState.setAnimation(0, 'Idle', true);
			this.leftPlayer.animationState.timeScale = this.gameSpeed;
			this.leftPlayer.setInteractive();
			this.leftPlayer.on('pointerup', event => {
				this.loadCharacterInfoPopup();
			});
			if (this.rightPlayer != null) {
				this.rightPlayer.destroy();
			}
			this.rightPlayer = this.add.spine(550, 440, 'punchingbag-data', 'punchingbag-atlas');
			this.rightPlayer.setOrigin(0.5, 0.3);
			this.rightPlayer.scaleX = 0.5;
			this.rightPlayer.scaleY = 0.5;

			this.rightPlayer.animationState.setAnimation(0, 'idle', true);
			this.rightPlayer.animationState.timeScale = this.gameSpeed;

			this.myName = this.add.text(this.leftPlayer.x, this.leftPlayer.y - 250, characterData.data.displayName, AppController.getText(30)).setOrigin(0.5, 0);

			this.leftPower = new CurrencySlot(this, this.myName.x, this.myName.y - 30, 'icon_power', 150);
			this.leftPower.updateNumber(characterData.data.power);

			this.myEquipments = characterData.data.equipments;
			this.updateEquipments();

			this.coinSlot.visible = true;
			this.coinSlot.updateNumber(characterData.data.displayCoin);
			this.updateRankData(characterData.data.displayCoin);

			this.pawSlot = new CurrencySlot(this, this.gamebg.width - 100, this.staminaSlot.y, 'paw_token');
			this.pawSlot.on('pointerup', event => {
				this.loadShopView();
			});
			this.pawSlot.updateNumber(characterData.data.displayPaw);

			var canx3 = true;
			if (characterData.data.displayPaw < 50) {
				canx3 = false;
			}

			this.staminaSlot.maxNumber = characterData.data.maxStamina;
			this.staminaSlot.updateNumber(characterData.data.stamina);

			if (this.triggerTimer == null) {
				this.triggerTimer = this.time.addEvent({
					callback: this.timerEvent,
					callbackScope: this,
					delay: 1000 * this.myCharacterData.time_between_turn, //4000 / this.gameSpeed, // 1000 = 1 second
					loop: true,
				});
			}
			this.onCreate = false;

			if (this.myCharacterData.claimedAirdrop != true) {
				this.button6.showNotification();
			}

			const maxOfflineSecond = this.myCharacterData.minelist[1].level[this.myMineLevel[1]].value;

			//var newPopupScene = this.scene.launch('OfflinePopup', { offlineReward: this.myCharacterData.offlineReward, offlineSecond: this.myCharacterData.offlineSecond });

			if (this.myCharacterData.offlineReward != null && this.myCharacterData.offlineReward > 0) {
				if (this.myCharacterData.offlineSecond != null && this.myCharacterData.offlineSecond > 60) {
					var newPopupScene = this.scene.launch('OfflinePopup', { offlineReward: this.myCharacterData.offlineReward, offlineSecond: this.myCharacterData.offlineSecond, canx3: canx3, maxOffline: maxOfflineSecond });
				}
			}
		}
		/*this.isBoss = true;
		if (this.isBoss) {
			this.switchBossBattle();
		}*/
	}

	updateRankData(coinValue) {
		//this.rankTxt.text = this.myCharacterData.myRank;

		var newRankName = this.myCharacterData.myRank;

		var previosRank = this.rankList[0].coin;
		var nextRank = this.rankList[1].coin;

		var isPassed = false;

		for (var i = 0; i < this.rankList.length; i++) {
			if (coinValue < this.rankList[i].coin) {
				break;
			}

			if (this.rankList[i].name == this.myCharacterData.myRank) {
				isPassed = true;
			}

			if (coinValue >= this.rankList[i].coin && isPassed) {
				newRankName = this.rankList[i].name;
			}
		}

		for (var i = 0; i < this.rankList.length; i++) {
			previosRank = this.rankList[i].coin;
			nextRank = this.rankList[i + 1].coin;
			if (this.rankList[i].name == newRankName) {
				break;
			}
		}

		this.rankProgress.maxNumber = nextRank;
		this.rankProgress.setProgress(coinValue);
		this.myCharacterData.myRank = newRankName;
		this.rankTxt.text = 'Rank: ' + newRankName + ' (' + AppController.formatNumber(coinValue) + '/' + AppController.formatNumber(nextRank) + ')';
	}

	async loadNewOpponent(isBoss = false) {
		if (this.isWaitNewBattle == true) {
			this.startButton.disableBtn = true;
			this.powerUpButton.disableBtn = true;
			return;
		}

		this.isLoading = true;

		this.startButton.disableBtn = true;
		this.powerUpButton.disableBtn = true;
		this.bossBtn.disableBtn = true;

		var battleResult;
		if (isBoss) {
			battleResult = await AppController.startNewBossFight();
		} else {
			battleResult = await AppController.startNewBattle();
		}

		console.log(battleResult);
		this.isLoading = false;

		if (battleResult.data.success == false) {
			if (battleResult.data.hasOwnProperty('playerExists') && battleResult.data.playerExists == false) {
				await this.loadCharsPopup();

				return;
			}

			if (battleResult.data.hasOwnProperty('stamina')) {
				this.staminaSlot.updateNumber(battleResult.data.stamina);

				return;
			}

			//this.countDownText.visible = true;
			//this.coinSlot.visible = false;

			this.countDownTween = this.tweens.addCounter({
				from: battleResult.data.wait_second,
				to: 0,
				duration: battleResult.data.wait_second * 1000,
				ease: 'linear',
				onUpdate: tween => {
					const value = Math.round(tween.getValue());
					this.isWaitNewBattle = true;

					this.startButton.setText('Next battle in: ' + value);
				},

				onComplete: tween => {
					this.isWaitNewBattle = false;
					this.startButton.setText('Starting battle...');
					this.loadNewOpponent();
				},
			});

			return;
		}
		if (this.bgMusic) {
			this.bgMusic.stop();
		}
		var randomNumber = Math.random();
		if (randomNumber < 0.5) {
			this.battleMusic1.play();
		} else {
			this.battleMusic2.play();
		}

		this.resetBattleField();

		this.countDownText.visible.false;
		this.countDownText.text = '';
		this.coinSlot.visible = true;
		this.button0.disableBtn = true;

		this.coinSlot.updateNumber(battleResult.data.myData.displayCoin);
		this.updateRankData(battleResult.data.myData.displayCoin);

		this.staminaSlot.maxNumber = battleResult.data.myData.maxStamina;
		this.staminaSlot.updateNumber(battleResult.data.myData.stamina);

		this.myEquipments = battleResult.data.myData.equipments;
		this.updateEquipments();

		if (this.tutorialHand) {
			this.tutorialHand.visible = false;
		}

		this.onBattle = true;
		this.checkMutantButtonDisable();

		if (battleResult.data.opponent || isBoss) {
			this.currentTurn = -1;
			this.isUpdatingCoin = false;
			this.battleInfo = battleResult.data.battleInfo;
			this.eloIncrease = battleResult.data.eloIncrease;
			if (this.eloIncrease > 0) {
				this.eloIncrease = '+' + this.eloIncrease.toString();
			}

			this.leftHP = new ProgressBar(this, 50, 300, 'hp_green', battleResult.data.myData.hp);

			this.leftPlayer = this.add.spine(150, 550, battleResult.data.myData.skin + '-data', battleResult.data.myData.skin + '-atlas');
			this.leftPlayer.setOrigin(0.5, 0.5);
			this.leftPlayer.skinName = battleResult.data.myData.skin;
			this.leftPlayer.scaleX = 0.5;
			this.leftPlayer.scaleY = 0.5;

			this.leftPlayer.animationState.setAnimation(0, 'Idle', true);
			this.leftPlayer.animationState.timeScale = this.gameSpeed;

			const leftSkillId = battleResult.data.myData.skill.id;
			const rightSkillId = isBoss ? 'atomic_breath' : battleResult.data.opponentData.skill.id;

			for (var i = 0; i < battleResult.data.setting.skills.length; i++) {
				if (battleResult.data.setting.skills[i].id == leftSkillId) {
					this.leftSkillData = battleResult.data.setting.skills[i];
				}
				if (battleResult.data.setting.skills[i].id == rightSkillId) {
					this.rightSkillData = battleResult.data.setting.skills[i];
				}
			}

			this.leftSkill = new SkillButton(this, 150, 630, battleResult.data.myData.character.skill_attack.thumb, battleResult.data.myData.skill.dmg_gauge);
			this.myName = this.add.text(this.leftHP.x, this.leftHP.y - 40, battleResult.data.myData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0, 0);
			this.leftPower = new CurrencySlot(this, this.myName.x + 85, this.myName.y - 40, 'icon_power', 150);
			this.leftPower.updateNumber(battleResult.data.myData.power);

			if (isBoss == false) {
				this.rightHP = new ProgressBar(this, 470, 300, 'hp_red', battleResult.data.opponentData.hp);
				this.rightPlayer = this.add.spine(550, 550, battleResult.data.opponentData.skin + '-data', battleResult.data.opponentData.skin + '-atlas');
				this.rightPlayer.setOrigin(0.5, 0.5);
				this.rightPlayer.skinName = battleResult.data.opponentData.skin;
				this.rightPlayer.scaleX = -0.5;
				this.rightPlayer.scaleY = 0.5;

				this.rightPlayer.animationState.setAnimation(0, 'Idle', true);
				this.rightPlayer.animationState.timeScale = this.gameSpeed;
				this.rightSkill = new SkillButton(this, 550, 630, battleResult.data.opponentData.character.skill_attack.thumb, battleResult.data.opponentData.skill.dmg_gauge);
				this.oppName = this.add.text(this.rightHP.x + this.rightHP.width, this.rightHP.y - 40, battleResult.data.opponentData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'right' }).setOrigin(1, 0);
				this.rightPower = new CurrencySlot(this, this.oppName.x - 75, this.oppName.y - 40, 'icon_power', 150);

				this.rightPower.updateNumber(battleResult.data.opponentData.power);
			} else {
				this.bossPanel.destroy();
				this.rightHP = new ProgressBar(this, 20, this.coinSlot.y, 'hp_red', battleResult.data.opponentData.maxHP, this.gamebg.width - 40);
				this.rightHP.setProgress(battleResult.data.opponentData.hp);
				this.oppName = this.add.text(this.gamebg.width / 2, this.rightHP.y - 70, 'Boss Level 1', { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: '#fff', align: 'right' }).setOrigin(0.5, 0);
				this.rightPlayer = this.add.spine(650, 550, 'shimo-data', 'shimo-atlas');
				this.rightPlayer.setOrigin(0.5, 0.5);
				this.rightPlayer.scaleX = 0.5;
				this.rightPlayer.scaleY = 0.5;
				this.rightPlayer.animationState.setAnimation(0, 'Idle', true);
				this.rightPlayer.animationState.timeScale = this.gameSpeed;

				this.myCharacterData.bossFreeFight = battleResult.data.myData.bossFreeFight;

				if (this.myCharacterData.bossFreeFight > 0) {
					this.staminaPerBattleTxt.text = 'Free: ' + this.myCharacterData.bossFreeFight;
				} else {
					this.staminaPerBattleTxt.text = 'Cost: 50 PAW';
				}

				this.myCharacterData.displayPaw = battleResult.data.myPaw;
				this.pawReward = battleResult.data.myData.pawReward;
			}
		}

		this.time.delayedCall(1000, this.battleCallback, null, this);
	}

	createReward(maxParticle = 5, newTotalCoin = -1) {
		if (maxParticle == 0) {
			return;
		}
		//this.sound.play('coin_drop');

		const emitter = this.add.particles(550, 500, 'main2', {
			frame: 'token_energy',
			bounce: 0.5,
			speed: { min: 600, max: 700 },
			maxVelocityX: 1200,
			maxVelocityY: 1200,
			angle: { min: -100, max: -80 },
			gravityY: 1600,
			//y: { start: 30, end: 500, ease: 'bounce.out' },
			lifespan: 4000,

			maxParticles: Math.min(maxParticle, 10),
			sortProperty: 'lifeT',
			sortOrderAsc: true,
		});

		emitter.addParticleBounds(0, 0, 700, 650, false, false, false, true);

		this.time.addEvent({
			callback: function() {
				emitter.gravityY = 0;
				const well = emitter.createGravityWell({
					x: -250,
					y: -650,
					power: 10,
					epsilon: 10,
					gravity: 1600,
				});
				if (newTotalCoin >= 0 && this.isUpdatingCoin == false) {
					this.isUpdatingCoin = true;
					const currentNumber = this.coinSlot.currentNumber;

					if (this.coinSlot.currentNumber < this.mutantPrice && this.coinSlot.currentNumber + newTotalCoin >= this.mutantPrice) {
						this.shiba.animationState.setAnimation(0, 'Action_01', false);
					}

					this.coinSlot.updateNumber(currentNumber + newTotalCoin);
					this.updateRankData(currentNumber + newTotalCoin);
					this.checkMutantButtonDisable();
				}
				//
			},
			callbackScope: this,
			delay: 2000, // 1000 = 1 second
			loop: false,
		});
	}

	glowMutantButton() {
		this.effectMutantButton.visible = true;
		/*if (this.butttonTween != null) {
			this.butttonTween.stop();
			this.button0.postFX.clear();
		}

		this.button6.hideNotification();
		const fx1 = this.button0.postFX.addGlow(0x69fbfd, 0, 0, false, 0.05, 32);

		this.butttonTween = this.tweens.add({
			targets: fx1,
			outerStrength: 4,
			yoyo: true,
			loop: -1,
			hold: 500,
			delay: 500,
			ease: 'sine.inout',
		});*/
	}

	getSkillName(skinName) {
		if (skinName == 'mechzilla') {
			return 'rocket';
		} else if (skinName == 'dogzilla') {
			return 'skill_2';
		} else {
			return 'skill_1';
		}
	}
}
