import AppController from '../controller/AppController';
export default class PopupButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, text, buttonWidth = 200, atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.txt = scene.add.text(0, 0, text, AppController.getText(26)).setOrigin(0.5, 0.5);

		this.bg = scene.add.nineslice(0, 0, atlas, name + '_none', buttonWidth, 0, 7, 7, 0, 0);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);

		this.on('pointerover', function() {
			this.bg.setTexture('main2', name + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture('main2', name + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture('main2', name + '_none');
		});

		this.on('pointerup', function() {
			this.bg.setTexture('main2', name + '_none');
		});
	}

	setText(value) {
		this.txt.text = value;
	}

	set disableBtn(value) {
		if (this._disabled == value) {
			return;
		}

		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main2', this.btnName + '_disable');
		} else {
			this.setInteractive();
			this.bg.setTexture('main2', this.btnName + '_none');
		}
	}
}
