import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import SimpleImageButton from '../components/SimpleImageButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import AppController from '../controller/AppController';

export default class EarnPopup extends Phaser.Scene {
	constructor() {
		super('EarnPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'EarnPopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const SAMPLE_DATA = [
			{ type: 'header', label: 'Basic' },
			{ type: 'task', label: 'Join our Telegram Group', icon: 'earn_telegram', reward: 120, id: 'telegram_join', finished: false },

			{ type: 'task', label: 'Invite a friend', icon: 'earn_invite', reward: 120, id: 'telegram_join', finished: false },
			{ type: 'header', label: 'Telegram Premium' },
			{ type: 'task', label: 'Telegram Premium', icon: 'tele_premium', reward: 120, id: 'telegram_join', finished: true },
		];

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 1000, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Earn', BIG_LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('EarnPopup');
		});

		var childPanel = this.rexUI.add.sizer({
			x: 0,
			y: 0,
			orientation: 'y',
			space: { item: 10, top: 10, bottom: 10 },
		});

		this.scene.launch('LockScreen');
		var taskList = await AppController.getTasks();
		this.scene.stop('LockScreen');
		//taskList.data.push({ icon: 'earn_checkin', id: 'daily_checkin', label: 'Daily Check-in', reward: 10, type: 'task' });
		//taskList.data.push({ label: 'Twitter/X', type: 'header' });
		//taskList.data.push({ icon: 'earn_twitter', id: 'earn_twitter', label: 'Follow Dog Mutant X', reward: 500, type: 'task' });

		for (var i = 0; i < taskList.data.length; i++) {
			childPanel.add(this.createItemRenderer(i + 1, taskList.data[i]));
		}

		//var abc = this.createItemRenderer();

		var config = {
			x: popupBG.x,
			y: popupBG.y - 10,
			width: popupBG.width,
			height: popupBG.height - 120,
			scrollMode: 0,
			slider: false,
			panel: {
				child: childPanel,
				mask: {
					padding: 0,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		panel.setChildrenInteractive().on('child.click', async function(child) {
			if (this.isDisable == true) {
				return;
			}
			if (child.inputData.type != 'task') {
				return;
			}

			if (child.inputData.finished == true) {
				return;
			}

			console.log(child);

			var canDoTask = true;

			if (child.inputData.id == 'daily_checkin') {
				//Telegram.WebApp.openLink('https://twitter.com/i/oauth2/authorize?response_type=code&client_id=enROekx4WlRCWmpuMU14T0pWZzE6MTpjaQ&redirect_uri=https://local.dogmutant.com/dog-mutant/us-central1/auth?uid=1988172628&scope=tweet.read%20users.read%20follows.read&state=state&code_challenge=challenge&code_challenge_method=plain', { try_instant_view: true });
				//return;

				var checkinResult = await AppController.checkInTon();

				if (checkinResult.success == false) {
					this.scene.scene.launch('Alert', { mess: 'Transaction not sent!' });
					return;
				}
			}
			if (child.inputData.id == 'earn_twitter') {
				//Telegram.WebApp.openLink('https://twitter.com/i/oauth2/authorize?response_type=code&client_id=enROekx4WlRCWmpuMU14T0pWZzE6MTpjaQ&redirect_uri=https://local.dogmutant.com/dog-mutant/us-central1/auth?uid=1988172628&scope=tweet.read%20users.read%20follows.read&state=state&code_challenge=challenge&code_challenge_method=plain', { try_instant_view: true });
				//return;
			}

			this.scene.scene.launch('LockScreen');
			const dotaskresult = await AppController.doTask(child.inputData.id);
			this.scene.scene.stop('LockScreen');

			console.log(dotaskresult);
			if (dotaskresult.success == false) {
				this.scene.scene.launch('Alert', dotaskresult);
			} else {
				this.scene.scene.get('MainScene').pawSlot.updateNumber(dotaskresult.paw);
				this.scene.scene.launch('Alert', dotaskresult);
				this.scene.scene.stop('EarnPopup');
			}

			//gotoTelegramLink('https://t.me/$VQNgakEukFcIAQAAYmDOYyrx1ig');
		});
	}

	createItemRenderer(index, data) {
		var container = this.add.container();
		container.inputData = data;
		var name;
		if (data.type == 'header') {
			name = this.add.text(-300, 0, data.label, AppController.getText(40)).setOrigin(0, 0.5);
			container.setSize(name.width, name.height);
		} else {
			var itemBg;
			if (data.finished) {
				itemBg = this.add.nineslice(0, 0, 'main2', 'rank_row_grey_bg', 600, 120, 13, 13, 9, 18).setOrigin(0.5, 0.5);
			} else {
				itemBg = this.add.nineslice(0, 0, 'main2', 'rank_row_bg', 600, 120, 13, 13, 9, 18).setOrigin(0.5, 0.5);
			}

			container.add(itemBg);
			container.setSize(itemBg.width, itemBg.height);
			name = this.add.text(-200, -10, data.label, AppController.getText(30)).setOrigin(0, 1);
			var icon = this.add.image(-250, 0, 'main2', data.icon);
			var earn_icon = this.add.image(name.x, 0, 'main2', 'paw_token_1').setOrigin(0, 0);
			earn_icon.setScale(0.5, 0.5);

			var earn = this.add.text(earn_icon.x + 90, earn_icon.y, '+' + data.reward, AppController.getText(30, '#fff200')).setOrigin(1, 0);

			container.add(icon);
			container.add(earn_icon);
			container.add(earn);

			var goIcon;
			if (data.finished) {
				goIcon = this.add.image(220, 0, 'main2', 'icon_done').setOrigin(0, 0.5);
			} else {
				var go = this.add.text(200, 0, 'Go', AppController.getText(40)).setOrigin(0, 0.5);
				goIcon = this.add.image(245, 0, 'main2', 'icon_go').setOrigin(0, 0.5);
				container.add(go);
			}
			container.add(goIcon);
		}
		container.add(name);

		return container;

		var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		var elo = this.add.text(-150, -5, 'Elo: ' + data.elo.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);

		container.add(name);
		container.add(elo);
		//
		container.add(indexTxt);

		return container;
	}
}
