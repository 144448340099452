import Phaser from 'phaser';
import AppController from '../controller/AppController';
export default class EnhanceResultPopup extends Phaser.Scene {
	constructor() {
		super('EnhanceResultPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'EnhanceResultPopup' });
	}

	async init(data) {
		console.log(data);
		this.resultData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontStyle: 'bold', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 480, 720, 256, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var textTitle = 'SUCCESS';
		var textColor = '#00ff00';
		if (this.resultData.isWin == false) {
			var textTitle = 'FAILED';
			var textColor = '#ff161f';
		}

		var text = this.add.text(-100, r1.y - 50, textTitle, AppController.getText(70, textColor, 'bold')).setOrigin(0.5, 0.5);

		this.tweens.add({
			targets: text,
			x: r1.x,
			ease: 'Power2',
			duration: 500,
		});
		var scene = this;
		setTimeout(function() {
			scene.scene.stop('EnhanceResultPopup');
		}, 1000);
	}
}
