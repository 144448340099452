import AppController from '../controller/AppController';
export default class TabButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, text, atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.atlas = atlas;
		this.txt = scene.add.text(0, 0, text, AppController.getText(24)).setOrigin(0.5, 0.7);
		this.bg = scene.add.nineslice(0, 0, 'main2', 'rank_row_bg', 200, 70, 13, 13, 9, 18);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);

		this.on('pointerover', function() {
			this.bg.setTexture(this.atlas, 'rank_row_player_bg');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture(this.atlas, 'rank_row_player_bg');
		});

		this.on('pointerout', function() {
			if (this.selected) {
				this.bg.setTexture(this.atlas, 'rank_row_player_bg');
			} else {
				this.bg.setTexture(this.atlas, 'rank_row_bg');
			}
		});

		this.on('pointerup', function() {
			if (this.selected) {
				this.bg.setTexture(this.atlas, 'rank_row_player_bg');
			} else {
				this.bg.setTexture(this.atlas, 'rank_row_bg');
			}
		});
	}

	selectedButton(isSelected) {
		this.selected = isSelected;
		if (isSelected) {
			this.bg.setTexture(this.atlas, 'rank_row_player_bg');
		} else {
			this.bg.setTexture(this.atlas, 'rank_row_bg');
		}
	}

	set disableBtn(value) {
		this._disabled = value;
		console.log(value);

		if (value == true) {
			this.removeInteractive();
			this.bg.setTexture(this.atlas, this.btnName + '_disable');
			//this.bg.setTexture(this.atlas, this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture(this.atlas, this.btnName + normalState);
		}
	}
}
